.Dropdown {
  display: inline-block;
  margin: 4px 0;

  &--raised {
    z-index: 12;
  }

  &--default {
    width: 180px;
  }

  &--wide {
    width: 390px;
  }
}
