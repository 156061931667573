.DeleteIcon {
  position: relative;
  display: inline-block;

  .DeleteDialog {
    position: absolute;
    top: -140px;
    right: -102px;
    width: 268px;
    height: 136px;
    background-color: #fff;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
    border: solid 1px #ddd;
    padding: 18px 16px 18px 16px;
    font-size: 0.9rem;

    &:after {
      content: '';
      position: absolute;
      border-top: 12px solid #fff;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-bottom: 12px solid transparent;
      bottom: -24px;
      left: 50%;
    }

    p {
      margin-bottom: 5px;
    }

    &__Buttons {
      margin-top: 20px;

      .Button {
        margin-right: 12px;
      }
    }
  }
}
