/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.NavigationTab {
  display: inline-block;
  margin: 0 18px 0 0;
  padding: 0;
  color: #818181; }
  .NavigationTab__Link {
    text-decoration: none;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: #818181; }
    .NavigationTab__Link:hover {
      text-decoration: none;
      color: #0e141a; }
    .NavigationTab__Link--active {
      color: #0e141a; }

@media (max-width: 575.98px) {
  .NavigationTab {
    margin: 0 20px 0 0; } }

.NavigationTab--active {
  color: #0e141a; }
  .NavigationTab--active::after {
    content: "";
    display: block;
    margin: 10px 0;
    border-bottom: 2px solid #4db5f0; }

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  height: 45px; }
