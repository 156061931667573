/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.SettingInfo__Toolbar {
  height: 60px; }
  .SettingInfo__Toolbar .back {
    position: absolute;
    line-height: 60px;
    vertical-align: middle; }
    .SettingInfo__Toolbar .back a {
      color: #0e141a; }
  .SettingInfo__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

@media (max-width: 767.98px) {
  .SettingInfo__Toolbar {
    height: 40px; }
    .SettingInfo__Toolbar .back {
      position: absolute;
      float: left;
      line-height: 36px;
      margin-left: 30px; }
    .SettingInfo__Toolbar .title {
      line-height: 40px;
      font-size: 1rem; } }

.SettingInfo__NavigationTabs {
  padding: 20px 25px; }
  .SettingInfo__NavigationTabs .title {
    margin-top: 30px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }
  .SettingInfo__NavigationTabs .settingInfoForm {
    margin-top: 22px; }
    .SettingInfo__NavigationTabs .settingInfoForm .imageContainer {
      float: left;
      width: 180px;
      margin-right: 50px; }
    .SettingInfo__NavigationTabs .settingInfoForm .propertyImage {
      width: 180px;
      position: relative;
      height: 172px; }
      .SettingInfo__NavigationTabs .settingInfoForm .propertyImage__preview {
        opacity: 1.0; }
      .SettingInfo__NavigationTabs .settingInfoForm .propertyImage__fileInput {
        position: absolute;
        top: 40px;
        left: 34px;
        opacity: 0.0; }
    .SettingInfo__NavigationTabs .settingInfoForm .propertyImage:hover > .propertyImage__preview {
      opacity: 0.5; }
    .SettingInfo__NavigationTabs .settingInfoForm .propertyImage:hover > .propertyImage__fileInput {
      opacity: 0.7; }
    .SettingInfo__NavigationTabs .settingInfoForm .basicInfo .formInput1 {
      margin-bottom: 22px; }
    .SettingInfo__NavigationTabs .settingInfoForm .basicInfo .formInput2 {
      margin-bottom: 22px; }
    .SettingInfo__NavigationTabs .settingInfoForm .beds24InfoForm {
      margin-top: 22px; }
      .SettingInfo__NavigationTabs .settingInfoForm .beds24InfoForm__Row {
        margin-left: 0; }
      .SettingInfo__NavigationTabs .settingInfoForm .beds24InfoForm .formInput {
        margin-bottom: 22px; }
      .SettingInfo__NavigationTabs .settingInfoForm .beds24InfoForm .bed24FormInput {
        margin-top: 23px; }
  .SettingInfo__NavigationTabs .footButtons {
    margin-top: 40px; }
    .SettingInfo__NavigationTabs .footButtons .col {
      padding-left: 0; }
    .SettingInfo__NavigationTabs .footButtons .footButton__delete {
      float: right; }
