/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#AutoActionMail .AutoActionMail__Toolbar {
  height: 60px; }
  #AutoActionMail .AutoActionMail__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

#AutoActionMail .Edit .DeleteButton {
  margin: 20px 8px 0 auto; }
