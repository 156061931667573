.ListGroup {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
  width: 100%;

  &--default {
    > .ListItem:not(:last-child) {
      border-bottom: 1px solid rgba(0,0,0,.125);
    }
  }

  &--underline {
    > .ListItem {
      border-bottom: 1px solid rgba(0,0,0,.125);
    }
  }

  &--surround {
    border: 1px solid rgba(0,0,0,.125);

    > .ListItem:not(:last-child) {
      border-bottom: 1px solid rgba(0,0,0,.125);
    }
  }
}



