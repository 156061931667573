@charset "UTF-8";
.Navigation {
  position: fixed;
  z-index: 5;
  background-color: #262d34;
  padding: 0;
  width: 200px;
  /* 200px固定 */
  height: 100%; }
  .Navigation .Logo {
    margin: 10px 12px;
    color: #999; }
  .Navigation .User {
    margin: 22px 12px;
    width: 90%;
    font-weight: 100;
    color: #fff; }

@media (max-width: 767.98px) {
  .Navigation {
    display: none; }
    .Navigation--show {
      display: block;
      z-index: 101; } }
