.monthlyProcess {
  padding-top: 5px;
  padding-left: 25px;

  .LocalArea {
    .searchCheckIn {
      width: 110px;
      height: 34px;
    }
    .searchHouse {
      width: 240px;
      margin: 18px 8px;
    }

    .Button {
      margin: 18px 5px;
    }

    &:after {
      clear: both;
    }
  }

  .display {

    margin: 18px 0;

    .displayTarget {
      margin-bottom: 5px;

      .targetDate {
        background-color: #000;
        color: #fff;
        padding: 8px;
        margin-right: 12px;

      }
      .targetHotel {
        font-size: 1.4rem;
        margin-right: 12px;
      }

      .status {
        font-size: 0.75rem;

        color: #fff;
        padding: 4px;
        border-radius: 8px;
        margin-right: 12px;

        &--none {
          background-color: #c00;
        }
        &--saved {
          background-color: #888;
        }
        &--closed {
          background-color: #4DB5F0;
        }
      }
    }
  }

  .inputs {
    .closeButton {
      color: #fff;
      background-color: #000;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: 0.3rem;
      margin-left: 6px;

      &:hover {
        text-decoration: none;
      }
    }
    .Dropdown {
      width: 150px;
    }
    h3 {
      width: 100%;
      border-bottom: solid 1px #aaa;
    }
    table {
      tr { height: 38px; }
      td {
        .Dropdown {
          width: 95%;
        }
        .MoneyInput {
          width: 130px;
          margin-right: 4px;
        }
        .DatePicker {
          padding: 8px;
          width: 120px;
          height: 36px;
        }
        input {
          margin-right: 6px;

          .price {

            font-size: 1.5rem;
            margin-right: 3px;
          }


        }

      }
    }
    textarea {
      width: 100%;
      height: 120px;
    }
  }
  .actions {
    width: 100%;
    button {
      float: right;
      margin-top: 24px;
      margin-bottom: 24px;
      margin-right: 12px;
    }
  }

  .summary {
    width: 100%;

    th {
      width: 220px;
    }

    td{
      width: 120px;

      ul {
        padding-left: 0;

        li {
          list-style-type: none;
          text-align: right;
        }

        li.total {
          list-style-type: none;
          text-align: right;
          border-top: solid 1px;
        }
      }
    }
  }
}

