.PreviousLink {
  float: left;
  display: inline;

  &__Arrow {
    display: inline-block;
    vertical-align: middle;
  }

  &__Label {
    display: inline;
  }
}

@media (max-width: 767.98px) {
	.PreviousLink {

		&__Label {
	    display: none;
	  	}
	}
}