#TaskList {

  .condition__rooms {
    width: 240px;
    margin: 18px 8px;
  }

  .column--status {
    width: 8%;
  }

  .column--room_name {
    width: 22%;
  }

  .column--title {
    width: 30%;
  }

  .column--user_name {
    width: 14%;
  }

  .column--updated_at {
    width: 16%;
  }

  .column--action {
    width: 10%;
  }
}
