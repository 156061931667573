//@import 'bootstrap.scss';
//@import '@material/elevation/mdc-elevation';

html {
  height: 100%;
  font-size: 14px;
  color: #0e141a;
}

body {
  font-family: 'Noto Sans Japanese', sans-serif;
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

.Container {
  height: 100%;
}

.ContainerRow {
  height: 100%;
}

.AppMain {
  margin: 0;
  padding: 0;
}

//.date {
//  font-family: Lato, sans-serif;
//}

//.price {
//  font-family: Lato, sans-serif;
//}
//
//.email {
//  font-family: Lato, sans-serif;
//}
//
//.url {
//  font-family: Lato, sans-serif;
//}
//
//
//.hide {
//  display: none;
//}

#overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #0e141a;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(38, 45, 52, 0.6);
}
//#loader {
//  border: 4px solid black;
//  border-radius: 50%;
//  border-top: 4px solid white;
//  width: 32px;
//  height: 32px;
//  margin: auto;
//  margin-top: 45vh;
//  -webkit-animation: rotate-center 0.8s ease-out infinite ;
//  animation: rotate-center 0.8s ease-out infinite ;
//}


//@keyframes rotate-center {
//  0% {
//    -webkit-transform: rotate(0);
//    transform: rotate(0);
//  }
//  100% {
//    -webkit-transform: rotate(360deg);
//    transform: rotate(360deg);
//  }
//}


//.fade-enter {
//  opacity: 0.01;
//}
//
//.fade-enter-active {
//  opacity: 1;
//  transition: opacity 300ms ease-in;
//}
//
//.fade-exit {
//  opacity: 1;
//}
//
//.fade-exit-active {
//  opacity: 0;
//  transition: opacity 300ms ease-in;
//}

.multiLine {
  width: 340px;
  white-space: normal;
  overflow: scroll;
  text-overflow: initial;
  margin-bottom: 16px;
}

table.report {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

table.report th,table.report td {
  padding: 10px 0;
  text-align: center;
}

table.report tr:nth-child(odd){
  background-color: #eee
}
