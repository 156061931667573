/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#ConfigHotels .Toolbar__Hotel {
  height: 60px; }
  #ConfigHotels .Toolbar__Hotel .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

@media (max-width: 767.98px) {
  #ConfigHotels .Toolbar__Hotel {
    height: 40px; }
    #ConfigHotels .Toolbar__Hotel .title {
      line-height: 40px;
      font-size: 1rem; } }

#ConfigHotels .Hotels {
  height: 100%; }
  #ConfigHotels .Hotels__Title {
    padding-left: 24px;
    display: inline-block;
    margin-right: 12px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }
  #ConfigHotels .Hotels__List {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: stretch; }
    #ConfigHotels .Hotels__List .Item {
      width: 100%; }
    #ConfigHotels .Hotels__List--empty {
      width: 100%;
      height: 50%;
      /* fallback */
      height: 50vh;
      text-align: center;
      display: table; }
      #ConfigHotels .Hotels__List--empty .inner {
        display: table-cell;
        vertical-align: middle; }
        #ConfigHotels .Hotels__List--empty .inner .message {
          font-family: 'Noto Sans Japanese', sans-serif;
          font-size: 1.28rem;
          font-weight: 200;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #a2a2a2;
          margin-bottom: 20px; }
