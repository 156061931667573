/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#SettingSensor .SettingSensor__Toolbar {
  height: 60px; }
  #SettingSensor .SettingSensor__Toolbar .back {
    position: absolute;
    line-height: 60px;
    vertical-align: middle; }
    #SettingSensor .SettingSensor__Toolbar .back a {
      color: #0e141a; }
  #SettingSensor .SettingSensor__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

@media (max-width: 767.98px) {
  #SettingSensor .SettingSensor__Toolbar .back {
    position: absolute;
    margin: 0px 50px; } }

#SettingSensor .SettingSensor__NavigationTabs {
  padding: 20px 25px; }
  #SettingSensor .SettingSensor__NavigationTabs .title {
    margin-top: 30px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }
  #SettingSensor .SettingSensor__NavigationTabs .setupNote .ListTitle {
    height: 50px;
    vertical-align: middle;
    line-height: 30px; }
  #SettingSensor .SettingSensor__NavigationTabs .alertMail button {
    margin-top: 20px; }
