/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.CheckInManagement__Toolbar {
  height: 60px; }
  .CheckInManagement__Toolbar .back {
    position: absolute;
    line-height: 60px;
    vertical-align: middle; }
    .CheckInManagement__Toolbar .back a {
      color: #0e141a; }
  .CheckInManagement__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

@media (max-width: 767.98px) {
  .CheckInManagement__Toolbar {
    height: 40px; }
    .CheckInManagement__Toolbar .back {
      position: absolute;
      float: left;
      line-height: 36px;
      margin-left: 30px; }
    .CheckInManagement__Toolbar .title {
      line-height: 40px;
      font-size: 1rem; } }

#CheckInManagement .LocalArea .searchKeyword {
  height: 40px;
  margin: 18px 8px 18px 18px; }

#CheckInManagement .LocalArea .searchHouse {
  width: 240px;
  margin: 18px 8px; }

#CheckInManagement .LocalArea .searchCheckIn {
  width: 110px;
  height: 38px; }

#CheckInManagement .LocalArea .Button {
  margin: 18px 12px; }

#CheckInManagement .LocalArea .localArea--right {
  margin-left: auto;
  margin-right: 32px; }
  #CheckInManagement .LocalArea .localArea--right .Button {
    margin: 18px 12px 18px 2px; }

#CheckInManagement .LocalArea:after {
  clear: both; }

#CheckInManagement .Reservations {
  height: 100%;
  margin-bottom: 48px; }
  #CheckInManagement .Reservations__Title {
    padding-left: 24px;
    display: inline-block;
    margin-right: 12px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }
  #CheckInManagement .Reservations__List {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: stretch; }
    #CheckInManagement .Reservations__List .Item {
      width: 100%;
      padding: 24px; }
      #CheckInManagement .Reservations__List .Item .Cell {
        padding: 1px; }
      #CheckInManagement .Reservations__List .Item__Name {
        width: 50%; }
      #CheckInManagement .Reservations__List .Item__Hotel {
        width: 50%; }
      #CheckInManagement .Reservations__List .Item__Period {
        width: 100%; }
        #CheckInManagement .Reservations__List .Item__Period p {
          margin: 0; }
      #CheckInManagement .Reservations__List .Item__Referer {
        width: 50%; }
      #CheckInManagement .Reservations__List .Item__Action {
        width: 50%; }
        #CheckInManagement .Reservations__List .Item__Action button {
          margin: auto 5px; }
      @media (min-width: 1200px) {
        #CheckInManagement .Reservations__List .Item__Name {
          width: 24%; }
        #CheckInManagement .Reservations__List .Item__Hotel {
          width: 24%; }
        #CheckInManagement .Reservations__List .Item__Period {
          width: 30%; }
        #CheckInManagement .Reservations__List .Item__Referer {
          width: 7%; }
        #CheckInManagement .Reservations__List .Item__Action {
          width: 15%; }
          #CheckInManagement .Reservations__List .Item__Action button {
            margin: auto 5px; } }
    #CheckInManagement .Reservations__List--empty {
      width: 100%;
      height: 50%;
      /* fallback */
      height: 50vh;
      text-align: center;
      display: table; }
      #CheckInManagement .Reservations__List--empty .inner {
        display: table-cell;
        vertical-align: middle; }
        #CheckInManagement .Reservations__List--empty .inner .message {
          font-family: 'Noto Sans Japanese', sans-serif;
          font-size: 1.28rem;
          font-weight: 200;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #a2a2a2;
          margin-bottom: 20px; }

#CheckInManagement .CheckInManagement__Form {
  padding: 20px 25px; }
  #CheckInManagement .CheckInManagement__Form .row {
    margin-bottom: 22px; }
  #CheckInManagement .CheckInManagement__Form .imageContainer {
    float: left;
    width: 180px;
    margin-right: 50px; }
  #CheckInManagement .CheckInManagement__Form .propertyImage {
    width: 180px;
    position: relative;
    height: 172px; }
    #CheckInManagement .CheckInManagement__Form .propertyImage__preview {
      opacity: 1.0; }
    #CheckInManagement .CheckInManagement__Form .propertyImage__fileInput {
      position: absolute;
      top: 40px;
      left: 34px;
      opacity: 0.0; }
  #CheckInManagement .CheckInManagement__Form .propertyImage:hover > .propertyImage__preview {
    opacity: 0.5; }
  #CheckInManagement .CheckInManagement__Form .propertyImage:hover > .propertyImage__fileInput {
    opacity: 0.7; }
  #CheckInManagement .CheckInManagement__Form .basicInfo .formInput1 {
    margin-bottom: 22px; }
  #CheckInManagement .CheckInManagement__Form .basicInfo .formInput2 {
    margin-bottom: 22px; }
