/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.checkbox {
  width: auto;
  height: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0E141A;
  display: flex; }
  .checkbox-disabled {
    opacity: 0.1; }
  .checkbox__box {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: solid 1px #A2A2A2; }
    .checkbox__box:hover {
      border: solid 1px #4DB5F0; }
    .checkbox__box-disabled {
      border: solid 1px #A2A2A2; }
      .checkbox__box-disabled:hover {
        border: solid 1px #A2A2A2; }
    .checkbox__box-selected {
      border: solid 1px #4DB5F0;
      background: #4DB5F0 url("/client_assets/icon/icon-input-completed.svg"); }
  .checkbox__label {
    margin-left: 7px;
    line-height: 14px;
    cursor: pointer; }
