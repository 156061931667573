/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.SearchField {
  height: 40px;
  width: 260px;
  margin: 0;
  padding: 0;
  display: inline-block; }
  .SearchField input {
    background: url("/client_assets/icon/icon-search.svg") no-repeat;
    border: 0;
    outline: 0;
    padding: 0 0 0 25px;
    height: 40px;
    width: 100%;
    background-position: center left;
    border-bottom: solid 1px #A2A2A2; }
    .SearchField input:focus {
      border-bottom: solid 1px #4DB5F0;
      background: url("/client_assets/icon/icon-search-light-blue.svg") no-repeat;
      background-position: center left; }
    .SearchField input::-webkit-input-placeholder {
      color: #a2a2a2; }
    .SearchField input:-ms-input-placeholder {
      color: #a2a2a2; }
    .SearchField input::-ms-input-placeholder {
      color: #a2a2a2; }
    .SearchField input::placeholder {
      color: #a2a2a2; }
