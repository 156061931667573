.Toolbar {
  position: fixed;
  top: 0;
  right: 0;
  height: 60px;
  width: calc(100% - 200px);
  padding: 0px 24px;
  background-color: #fff;
  z-index: 13;
  line-height: 60px;
}

.hamnav {
  position: absolute;
  cursor: pointer;
  line-height: 40px;
  float: left;
}

@media (max-width: 586px) {
  .Toolbar {
    width: calc(100% - 0px);
    padding: 0px 10px;
    height: 40px;
    line-height: 0px;
  }
}

@media (max-width: 767.98px) {
  .Toolbar {
    width: calc(100% - 0px);
    height: 40px;
    line-height: 0px;
  }
}

@media (max-width: 768x) {
  .Toolbar {
    width: calc(100% - 54px);
  }
}

@media (min-width: 768px) {
  .Toolbar {
    width: calc(100% - 200px);
  }
  .hamnav {
    display: none;
  }
}
