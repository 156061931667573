.passwordReset {
  background-color: #262D34;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 0.9rem;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .form {
    margin: calc(50vh - 300px) auto;
    padding-top: 60px;
    width: 420px;
    height: 550px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 6px 0 rgba(14, 20, 26, 0.2);

    &__inner {
      width: 300px;
      margin: auto;

      &__message {
        margin: 40px 0;
        color: #0e141a;
      }

      &__sentMessage {
        color: #0e141a;
        text-align: center;
        width: 300px;
        height: 36px;
        margin: 0 60px;
      }

      &__inputArea {
        width: auto;
        height: 70px;

        .textInput {
          width: 300px;
        }
      }

      button {
        width: 300px;
        height: 36px;
        margin-top: 30px;
      }

      .backToLogin {
        margin-top: 32px;

        &:hover {
          cursor: pointer;
        }
        &__icon {
          vertical-align: middle;
        }
      }
    }
  }
}
