.FoldableList {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
  width: 100%;
  border-radius: 2px;
  background-color: #f9fafc;

  &--default {
    border: 1px solid #e8e8e8;

    > .ListTitle {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}


