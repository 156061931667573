.LocalArea {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 12px;

  &--left {
    margin-left: 32px;
    margin-right: auto;

    .Button {
      margin: 18px 12px 18px 2px;
    }
  }

  &--right {
    margin-left: auto;
    margin-right: 32px;

    .Button {
      margin: 18px 12px 18px 2px;
    }
  }
}
