.passwordResetUpdate {
  background: url('/client_assets/sample/login_bg.jpg') no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 0.9rem;

  &__background {
    width: 100%;
  }

  .form {
    margin: calc(50vh - 300px) auto;
    padding-top: 60px;
    width: 420px;
    height: 550px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 6px 0 rgba(14, 20, 26, 0.2);

    &__inner {
      width: 300px;
      margin: auto;

      &__message {
        margin: 40px 0;
        color: #0e141a;
      }

      &__sentMessage {
        color: #0e141a;
        text-align: center;
        width: 300px;
        height: 36px;
      }

      &__inputArea {
        width: auto;
        height: 160px;

        .textInput {
          width: 300px;
          margin-top: 20px;
        }
      }

      button {
        width: 300px;
        height: 36px;
      }

      .backToLogin {
        margin-top: 32px;

        &:hover {
          cursor: pointer;
        }
        &__icon {
          vertical-align: middle;
        }
        &__label {

        }
      }
    }
  }
}
