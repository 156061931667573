.ListItem {
  padding: 1.714285714rem;
  display: none;

  &:hover {
    background: #f9fafc;
  }

  &--visible {
    display: block;
  }
}
