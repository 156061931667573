.DatePicker {
  border: 0;
  border-bottom: 1px solid #A2A2A2;
  outline: 0;
  letter-spacing: 0.04em;
  padding-bottom: 0;
  white-space: nowrap;
  height: 22px;
  width: 100%;
  color: #A2A2A2;

  &--selected {
    color: #000;
  }

  &--disabled {
    border-bottom: none;
  }
}

.react-datepicker {

  &__header {
    font-size: 1rem;
  }

  &__time-list {
    padding: 0;
  }

  &__portal {
    background-color: rgba(38, 45, 52, 0.5);

    .react-datepicker-time__header {
      font-size: 1rem;
    }
  }
}
