/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  color: #0E141A;
  font-weight: normal;
  -webkit-margin-before: 0;
          margin-block-start: 0; }

@media (max-width: 767.98px) {
  .title {
    line-height: 40px;
    font-size: 1rem; } }
