@charset "UTF-8";
.dataProtection {
  margin: 40px auto 40px auto;
  padding: 30px 40px 40px 40px;
  background: #f9fafc; }
  @media (max-width: 769.9px) {
    .dataProtection {
      width: auto; } }
  @media (min-width: 770px) {
    .dataProtection {
      width: 60%; } }

.guestform {
  position: relative;
  display: flex;
  align-items: stretch;
  padding: 0 28px;
  margin: 0 auto;
  width: 960px; }
  .guestform__label {
    position: relative;
    width: 260px;
    padding-left: 20px;
    border-left: solid 2px #e8e8e8; }
    .guestform__label .circle {
      position: absolute;
      display: inline-block;
      left: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #000; }
    .guestform__label .guestMember {
      margin-top: -5px; }
  .guestform .labelGender {
    margin-left: 3px;
    margin-right: 8px; }
  .guestform__delete {
    cursor: pointer;
    width: 28px;
    height: 28px;
    position: absolute;
    right: 15px;
    top: -10px;
    background-color: #fff;
    border: solid 1px #e8e8e8;
    border-radius: 50%; }
    .guestform__delete:before {
      content: '×';
      color: #a2a2a2;
      font-size: 1.15rem;
      padding: 5px; }
    .guestform__delete .deleteDialog {
      position: absolute;
      top: -140px;
      left: -128px;
      width: 256px;
      height: 136px;
      background-color: #fff;
      filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
      border: solid 1px #ddd;
      padding: 18px 12px 18px 12px;
      font-size: 0.9rem; }
      .guestform__delete .deleteDialog:after {
        content: '';
        position: absolute;
        border-top: 12px solid #fff;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        border-bottom: 12px solid transparent;
        bottom: -24px;
        left: 50%; }
      .guestform__delete .deleteDialog p {
        margin-bottom: 3px; }
      .guestform__delete .deleteDialog .buttonGroup {
        margin-top: 20px; }
        .guestform__delete .deleteDialog .buttonGroup button {
          margin-right: 6px; }
  .guestform__finish {
    position: relative;
    width: 260px;
    padding-left: 20px;
    margin-bottom: 20px;
    padding-top: 0; }
    .guestform__finish .circle {
      position: absolute;
      display: inline-block;
      left: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #e8e8e8; }
  .guestform__inputform {
    width: 700px;
    padding: 0 0 20px 0;
    font-size: 0.8rem; }
    .guestform__inputform .inner {
      border-top: solid 1px #e8e8e8;
      border-bottom: solid 1px #e8e8e8;
      border-right: solid 1px #e8e8e8;
      border-left: solid 2px #0e141a;
      padding: 15px 25px; }
      .guestform__inputform .inner .form1 {
        margin: 10px 0; }
      .guestform__inputform .inner .form2 {
        margin: 10px 0; }
      .guestform__inputform .inner .fileInput {
        margin-top: 10px; }
      .guestform__inputform .inner .dropdown {
        display: block;
        width: 80%; }
      .guestform__inputform .inner .image {
        max-width: 85%; }
  .guestform__noform {
    width: 700px;
    padding: 0px 0 40px 0;
    border: none; }
    .guestform__noform .buttonGroup button {
      margin-right: 12px; }

.checkinComp {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(38, 45, 52, 0.5);
  z-index: 1000; }
  .checkinComp__background {
    width: 403px;
    height: 278px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  .checkinComp__image {
    width: 100%;
    text-align: center;
    padding: 40px 0; }
  .checkinComp__imageExpired {
    width: 100%;
    text-align: center;
    padding: 40px 0; }
  .checkinComp__messages {
    text-align: center; }
    .checkinComp__messages--en {
      font-size: 20px;
      font-family: Lato, sans-serif; }
    .checkinComp__messages--jp {
      font-size: 14px;
      font-family: 'Noto Sans Japanese', sans-serif; }

@media (max-width: 991.98px) {
  .guestform {
    display: block;
    padding: 0 10px;
    width: auto; }
    .guestform__label {
      padding-bottom: 20px;
      border: none; }
    .guestform__inputform {
      width: 100%; }
    .guestform__noform {
      width: 100%; } }
