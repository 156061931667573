.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  object-fit: contain;
  border: none;

  &-overview {
    background: url('/client_assets/icon/icon-nav-overview.svg') no-repeat;
    background-size: contain;
  }

  &-rooms {
    background: url('/client_assets/icon/icon-nav-rooms.svg') no-repeat;
    background-size: contain;
  }

  &-receptionist {
    background: url('/client_assets/icon/icon-nav-receptionist.svg') no-repeat;
    background-size: contain;
  }

  &-inn {
    background: url('/client_assets/icon/icon-nav-inn.svg') no-repeat;
    background-size: contain;
  }

  &-logout {
    background: url('/client_assets/icon/icon-nav-logout.svg') no-repeat;
    background-size: contain;
  }

  &-agreement {
    background: url('/client_assets/icon/icon-nav-agreement.svg') no-repeat;
    background-size: contain;
  }

  &-setting {
    background: url('/client_assets/icon/icon-nav-setting.svg') no-repeat;
    background-size: contain;

    &--black {
      background: url('/client_assets/icon/icon-setting.svg') no-repeat;
      background-size: contain;
    }
  }

  &-managed {
    background: url('/client_assets/icon/icon-nav-user-managed.svg') no-repeat;
    background-size: contain;
  }

  &-pdf {
    background: url('/client_assets/icon/icon-pdf.svg') no-repeat;
    background-size: contain;
  }

  &-img {
    background: url('/client_assets/icon/icon-img.svg') no-repeat;
    background-size: contain;
  }

  &-preview {
    background: url('/client_assets/icon/icon-preview.svg') no-repeat;
    background-size: contain;
  }

  &-trash {
    background: url('/client_assets/icon/icon-trash.svg') no-repeat;
    background-size: contain;
  }

  &-edit {
    &--black {
      background: url('/client_assets/icon/icon-edit.svg') no-repeat;
      background-size: contain;
    }
  }

  &-description {
    background: url('/client_assets/icon/icon-description.svg') no-repeat;
    background-size: contain;
  }
}
