.AccomodationTaxButton {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 16px 0;

  .AccomodationTaxDialog {

    table {
      width: 95%;
      tbody {
        tr {
          height: 32px;
        }
      }
    }

    input[type="text"],input[type="number"] {
      border: none;
      border-bottom: solid 1px #A2A2A2;
    }

    &__Buttons {
      padding-top: 12px;

      button {
        margin: 0 6px;
      }
    }

    textarea {
      resize: none;
      border: 2px solid #ddd;
    }
  }
}
