html {
  height: 100%;
  font-size: 14px;
  color: #0e141a; }

body {
  font-family: 'Noto Sans Japanese', sans-serif;
  height: 100%;
  margin: 0; }

#root {
  height: 100%; }

.Container {
  height: 100%; }

.ContainerRow {
  height: 100%; }

.AppMain {
  margin: 0;
  padding: 0; }

#overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #0e141a;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(38, 45, 52, 0.6); }

.multiLine {
  width: 340px;
  white-space: normal;
  overflow: scroll;
  text-overflow: initial;
  margin-bottom: 16px; }

table.report {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

table.report th, table.report td {
  padding: 10px 0;
  text-align: center; }

table.report tr:nth-child(odd) {
  background-color: #eee; }

#HotelStatus .Toolbar .title {
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 767.98px) {
  #HotelStatus .Toolbar .title {
    line-height: 40px;
    font-size: 1rem; } }

#HotelStatus .ListGroup .ListItem {
  margin: 0;
  padding: 15px 0; }
  #HotelStatus .ListGroup .ListItem .hotel-item-row {
    padding-left: 24px;
    margin: 0; }
    #HotelStatus .ListGroup .ListItem .hotel-item-row .label {
      font-size: 14px;
      color: #818181;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 0; }
    #HotelStatus .ListGroup .ListItem .hotel-item-row .name {
      width: 50%; }
      #HotelStatus .ListGroup .ListItem .hotel-item-row .name .hotel-name {
        margin-bottom: 0px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.DateRangeField {
  margin: auto 8px; }
  .DateRangeField > div {
    display: inline-block; }
  .DateRangeField__datepicker {
    width: 105px;
    height: 40px;
    border: 0;
    border-bottom: 1px solid #A2A2A2;
    outline: 0;
    letter-spacing: 0.04em;
    padding-bottom: 0;
    white-space: nowrap; }
    .DateRangeField__datepicker--not-selected {
      color: #A2A2A2; }
  .DateRangeField__arrow {
    width: 24px;
    height: 40px;
    object-fit: contain;
    border: none;
    border-bottom: 1px solid #A2A2A2;
    vertical-align: top; }
    .DateRangeField__arrow svg {
      margin-top: 8px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

TextInput,
TextInput::before,
TextInput::after {
  box-sizing: border-box;
  transition: all .3s; }

.textInput__label {
  white-space: nowrap;
  text-align: left;
  color: #0E141A;
  margin: 0;
  display: flex;
  letter-spacing: 0.01rem;
  align-items: center; }
  .textInput__label-error {
    color: #DB2D26; }
  .textInput__label .textInput__optionIcon {
    width: 15px;
    height: 15px;
    position: relative;
    left: 0;
    top: 0;
    background-size: contain;
    border: none;
    outline: none; }
  .textInput__label-required .textInput__optionIcon {
    background: url("/client_assets/icon/icon-input-required.svg") no-repeat; }
  .textInput__label-completed .textInput__optionIcon {
    background: url("/client_assets/icon/icon-input-completed.svg") no-repeat; }

.textInput__caution {
  padding: 0 4px;
  margin-left: 4px;
  background-color: #e6eaf1;
  border-radius: 2px;
  color: #0a2d73;
  font-size: 0.85rem;
  word-wrap: break-word;
  white-space: pre-wrap; }

.textInput__text {
  width: 100%;
  height: 22px;
  white-space: nowrap;
  text-align: left;
  line-height: 20px;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #A2A2A2;
  color: #0e141a;
  letter-spacing: 0.04em;
  background: transparent;
  padding-bottom: 0; }
  .textInput__text-error {
    color: #DB2D26;
    border-bottom: 1px solid #DB2D26; }
  .textInput__text:focus {
    outline: 0;
    color: #0E141A;
    border-bottom: 1px solid #4DB5F0; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.FileInput {
  height: 34px;
  vertical-align: middle; }
  .FileInput__type {
    display: inline-block;
    height: 32px;
    padding-top: 2px;
    vertical-align: middle; }
  .FileInput__fileArea {
    height: 34px;
    border-radius: 2px;
    border: solid 1px #a2a2a2;
    padding: 0 8px; }
  .FileInput__message {
    color: #0E141A;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 0.95rem; }
  .FileInput__file {
    height: 34px;
    display: none; }
  .FileInput__trash {
    display: inline-block;
    height: 31px;
    padding-top: 3px;
    vertical-align: middle;
    cursor: pointer; }

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  object-fit: contain;
  border: none; }
  .icon-overview {
    background: url("/client_assets/icon/icon-nav-overview.svg") no-repeat;
    background-size: contain; }
  .icon-rooms {
    background: url("/client_assets/icon/icon-nav-rooms.svg") no-repeat;
    background-size: contain; }
  .icon-receptionist {
    background: url("/client_assets/icon/icon-nav-receptionist.svg") no-repeat;
    background-size: contain; }
  .icon-inn {
    background: url("/client_assets/icon/icon-nav-inn.svg") no-repeat;
    background-size: contain; }
  .icon-logout {
    background: url("/client_assets/icon/icon-nav-logout.svg") no-repeat;
    background-size: contain; }
  .icon-agreement {
    background: url("/client_assets/icon/icon-nav-agreement.svg") no-repeat;
    background-size: contain; }
  .icon-setting {
    background: url("/client_assets/icon/icon-nav-setting.svg") no-repeat;
    background-size: contain; }
    .icon-setting--black {
      background: url("/client_assets/icon/icon-setting.svg") no-repeat;
      background-size: contain; }
  .icon-managed {
    background: url("/client_assets/icon/icon-nav-user-managed.svg") no-repeat;
    background-size: contain; }
  .icon-pdf {
    background: url("/client_assets/icon/icon-pdf.svg") no-repeat;
    background-size: contain; }
  .icon-img {
    background: url("/client_assets/icon/icon-img.svg") no-repeat;
    background-size: contain; }
  .icon-preview {
    background: url("/client_assets/icon/icon-preview.svg") no-repeat;
    background-size: contain; }
  .icon-trash {
    background: url("/client_assets/icon/icon-trash.svg") no-repeat;
    background-size: contain; }
  .icon-edit--black {
    background: url("/client_assets/icon/icon-edit.svg") no-repeat;
    background-size: contain; }
  .icon-description {
    background: url("/client_assets/icon/icon-description.svg") no-repeat;
    background-size: contain; }

.DeleteIcon {
  position: relative;
  display: inline-block; }
  .DeleteIcon .DeleteDialog {
    position: absolute;
    top: -140px;
    right: -102px;
    width: 268px;
    height: 136px;
    background-color: #fff;
    -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
    border: solid 1px #ddd;
    padding: 18px 16px 18px 16px;
    font-size: 0.9rem; }
    .DeleteIcon .DeleteDialog:after {
      content: '';
      position: absolute;
      border-top: 12px solid #fff;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-bottom: 12px solid transparent;
      bottom: -24px;
      left: 50%; }
    .DeleteIcon .DeleteDialog p {
      margin-bottom: 5px; }
    .DeleteIcon .DeleteDialog__Buttons {
      margin-top: 20px; }
      .DeleteIcon .DeleteDialog__Buttons .Button {
        margin-right: 12px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

Button {
  font-weight: normal;
  text-align: center;
  border-radius: 2px;
  outline: 0; }
  Button:focus {
    outline: 0; }

Button,
Button::before,
Button::after {
  box-sizing: border-box;
  transition: all .3s; }

.btn-primary {
  color: #4DB5F0;
  border: solid 1px #4DB5F0; }
  .btn-primary:hover {
    color: #F9FAFC;
    background-color: #4DB5F0; }
  .btn-primary:disabled {
    opacity: 0.2;
    color: #4DB5F0;
    background-color: #F9FAFC; }

.btn-secondary {
  color: #F9FAFC;
  background-color: #4DB5F0;
  border: solid 1px #4DB5F0; }
  .btn-secondary:hover {
    color: #c6d0e3;
    background-color: #1394de;
    border: solid 1px #1394de; }
  .btn-secondary:disabled {
    opacity: 0.2;
    color: #F9FAFC;
    background-color: #4DB5F0; }

.btn-dark {
  color: #0E141A;
  border: solid 1px #A2A2A2; }
  .btn-dark:hover {
    opacity: 0.6; }
  .btn-dark:disabled {
    opacity: 0.2; }

.btn-light {
  color: #0E141A;
  border: none; }
  .btn-light:hover {
    color: #0E141A;
    background-color: #E8E8E8; }
  .btn-light:disabled {
    opacity: 0.2;
    background-color: #F9FAFC; }

.btn-blue {
  color: #4DB5F0;
  border: none; }
  .btn-blue:hover {
    color: #4DB5F0;
    background-color: rgba(77, 181, 240, 0.1); }
  .btn-blue:disabled {
    opacity: 0.2;
    background-color: #F9FAFC; }

.btn-danger {
  color: #DB2D26;
  border: none; }
  .btn-danger:hover {
    color: #F9FAFC;
    background-color: #DB2D26; }
  .btn-danger:disabled {
    opacity: 0.2;
    color: #DB2D26;
    background-color: #F9FAFC; }

.btn-lg {
  height: 34px;
  line-height: 14px;
  padding: 8px 16px 9px 16px; }

.btn-md {
  font-size: 0.85rem;
  height: 26px;
  line-height: 14px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.SearchField {
  height: 40px;
  width: 260px;
  margin: 0;
  padding: 0;
  display: inline-block; }
  .SearchField input {
    background: url("/client_assets/icon/icon-search.svg") no-repeat;
    border: 0;
    outline: 0;
    padding: 0 0 0 25px;
    height: 40px;
    width: 100%;
    background-position: center left;
    border-bottom: solid 1px #A2A2A2; }
    .SearchField input:focus {
      border-bottom: solid 1px #4DB5F0;
      background: url("/client_assets/icon/icon-search-light-blue.svg") no-repeat;
      background-position: center left; }
    .SearchField input::-webkit-input-placeholder {
      color: #a2a2a2; }
    .SearchField input:-ms-input-placeholder {
      color: #a2a2a2; }
    .SearchField input::-ms-input-placeholder {
      color: #a2a2a2; }
    .SearchField input::placeholder {
      color: #a2a2a2; }

.Dropdown {
  display: inline-block;
  margin: 4px 0; }
  .Dropdown--raised {
    z-index: 12; }
  .Dropdown--default {
    width: 180px; }
  .Dropdown--wide {
    width: 390px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.checkbox {
  width: auto;
  height: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0E141A;
  display: flex; }
  .checkbox-disabled {
    opacity: 0.1; }
  .checkbox__box {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: solid 1px #A2A2A2; }
    .checkbox__box:hover {
      border: solid 1px #4DB5F0; }
    .checkbox__box-disabled {
      border: solid 1px #A2A2A2; }
      .checkbox__box-disabled:hover {
        border: solid 1px #A2A2A2; }
    .checkbox__box-selected {
      border: solid 1px #4DB5F0;
      background: #4DB5F0 url("/client_assets/icon/icon-input-completed.svg"); }
  .checkbox__label {
    margin-left: 7px;
    line-height: 14px;
    cursor: pointer; }


.DatePicker {
  border: 0;
  border-bottom: 1px solid #A2A2A2;
  outline: 0;
  letter-spacing: 0.04em;
  padding-bottom: 0;
  white-space: nowrap;
  height: 22px;
  width: 100%;
  color: #A2A2A2; }
  .DatePicker--selected {
    color: #000; }
  .DatePicker--disabled {
    border-bottom: none; }

.react-datepicker__header {
  font-size: 1rem; }

.react-datepicker__time-list {
  padding: 0; }

.react-datepicker__portal {
  background-color: rgba(38, 45, 52, 0.5); }
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1rem; }

.Snackbar {
  position: fixed;
  z-index: 100001;
  top: 5vh;
  left: 25vw;
  width: 50vw;
  margin: 0 auto;
  border-radius: 2px;
  background-color: rgba(38, 45, 52, 0.9);
  vertical-align: middle;
  padding: 10px; }
  .Snackbar__pict {
    width: 20px;
    height: 20px;
    margin: 10px;
    display: inline-block;
    float: left; }
  .Snackbar__pict--success {
    background: url("/client_assets/icon/icon-correct.svg") no-repeat;
    background-size: contain; }
  .Snackbar__message {
    color: #fff;
    line-height: 40px; }
  .Snackbar__close {
    cursor: pointer;
    display: inline-block;
    float: right;
    margin-top: 8px;
    margin-left: 5px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.subContent {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 14px;
  text-align: left;
  color: #818181; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.subTitle {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
  color: #0E141A; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  color: #0E141A;
  font-weight: normal;
  -webkit-margin-before: 0;
          margin-block-start: 0; }

@media (max-width: 767.98px) {
  .title {
    line-height: 40px;
    font-size: 1rem; } }

.NavigationItem {
  height: 40px;
  padding: 0;
  margin: 0;
  opacity: 0.5; }
  .NavigationItem:hover {
    background-color: #1a1c1f;
    opacity: 1.0; }
  .NavigationItem a {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 0 16px;
    text-decoration: none; }
  .NavigationItem__Row {
    width: 100%;
    height: 40px; }
  .NavigationItem__Icon {
    float: left;
    vertical-align: middle;
    height: 40px;
    line-height: 40px;
    padding: 5px 0; }
  .NavigationItem__Label {
    vertical-align: middle;
    height: 40px;
    line-height: 40px;
    font-weight: 100;
    color: #fff;
    padding: 0 10px; }

@media (max-width: 768x) {
  .NavigationItem__Label {
    display: none; } }

.SensorValue .labels {
  position: relative;
  font-family: Lato, sans-serif; }
  .SensorValue .labels .value-label {
    font-size: 1.43rem; }
  .SensorValue .labels .unit-label {
    position: absolute;
    top: 2px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.tag {
  display: inline;
  min-width: 37px;
  height: 19px;
  border-radius: 15px;
  padding: 2px 6px;
  font-family: NotoSansCJKjp, Lato, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-size: 12px;
  text-align: left; }
  .tag span {
    white-space: nowrap; }

.tag-check_in {
  background-color: #fdf3ea;
  color: #F67806; }

.tag-staying {
  background-color: #e5f8f3;
  color: #00BA8A; }

.tag-check_out {
  background-color: #e6eaf1;
  color: #0A2D73; }

.tag-cancelled {
  background-color: #A2A2A2;
  color: #0A2D73; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.NavigationTab {
  display: inline-block;
  margin: 0 18px 0 0;
  padding: 0;
  color: #818181; }
  .NavigationTab__Link {
    text-decoration: none;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: #818181; }
    .NavigationTab__Link:hover {
      text-decoration: none;
      color: #0e141a; }
    .NavigationTab__Link--active {
      color: #0e141a; }

@media (max-width: 575.98px) {
  .NavigationTab {
    margin: 0 20px 0 0; } }

.NavigationTab--active {
  color: #0e141a; }
  .NavigationTab--active::after {
    content: "";
    display: block;
    margin: 10px 0;
    border-bottom: 2px solid #4db5f0; }

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  height: 45px; }

.DeleteButton {
  position: relative;
  display: inline-block; }
  .DeleteButton .DeleteDialog {
    position: absolute;
    top: -125px;
    width: 256px;
    height: 108px;
    background-color: #fff;
    -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
    border: solid 1px #ddd;
    padding: 18px 16px 18px 16px;
    font-size: 0.9rem; }
    .DeleteButton .DeleteDialog:after {
      content: '';
      position: absolute;
      border-top: 12px solid #fff;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-bottom: 12px solid transparent;
      bottom: -24px;
      left: 50%; }
    .DeleteButton .DeleteDialog p {
      margin-bottom: 5px; }
    .DeleteButton .DeleteDialog__Buttons {
      margin-top: 20px;
      display: table;
      text-align: center;
      table-layout: fixed;
      width: 100%; }
      .DeleteButton .DeleteDialog__Buttons .Button {
        margin-right: 8px;
        margin-left: 8px; }

.PreviousLink {
  float: left;
  display: inline; }
  .PreviousLink__Arrow {
    display: inline-block;
    vertical-align: middle; }
  .PreviousLink__Label {
    display: inline; }

@media (max-width: 767.98px) {
  .PreviousLink__Label {
    display: none; } }

.ListItem {
  padding: 1.714285714rem;
  display: none; }
  .ListItem:hover {
    background: #f9fafc; }
  .ListItem--visible {
    display: block; }

.ListTitle {
  cursor: pointer;
  padding: 10px 24px; }
  .ListTitle .more {
    float: right; }
    .ListTitle .more button {
      position: relative;
      width: 24px;
      height: 24px;
      border: none;
      background-color: transparent;
      cursor: pointer; }
    .ListTitle .more svg {
      position: absolute;
      top: 0; }
  .ListTitle .status {
    transform: rotate(0deg); }
    .ListTitle .status--open {
      top: -24px;
      transform: rotate(90deg); }

.OtaLogo {
  display: inline-block;
  width: 26px;
  height: 26px;
  object-fit: contain;
  background-size: contain;
  border: none; }
  .OtaLogo-airbnb {
    background: url("/client_assets/icon/ota/icon-airbnb.png") no-repeat; }
  .OtaLogo-booking {
    background: url("/client_assets/icon/ota/icon-booking.png") no-repeat; }
  .OtaLogo-expedia {
    background: url("/client_assets/icon/ota/icon-expedia.png") no-repeat; }
  .OtaLogo-hotels {
    background: url("/client_assets/icon/ota/icon-hotels.png") no-repeat; }
  .OtaLogo-ctrip {
    background: url("/client_assets/icon/ota/icon-ctrip.svg") no-repeat; }
  .OtaLogo-rakuten {
    background: url("/client_assets/icon/ota/icon-rakuten.png") no-repeat; }
  .OtaLogo-ikyu {
    background: url("/client_assets/icon/ota/icon-ikyu.png") no-repeat; }
  .OtaLogo-jalan {
    background: url("/client_assets/icon/ota/icon-jalan.png") no-repeat; }
  .OtaLogo-hafh {
    background: url("/client_assets/icon/ota/icon-hafh.png") no-repeat; }
  .OtaLogo-ota {
    background: url("/client_assets/icon/ota/icon-ota.svg") no-repeat; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.Edit {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10001;
  overflow: scroll; }
  .Edit .Toolbar {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .Edit .Toolbar--left {
      width: auto;
      margin-left: 36px; }
      .Edit .Toolbar--left button {
        border: none;
        background-color: transparent;
        cursor: pointer; }
        .Edit .Toolbar--left button:hover {
          opacity: 0.6; }
    .Edit .Toolbar--center {
      width: 33%;
      text-align: center; }
    .Edit .Toolbar--right {
      width: auto; }
      .Edit .Toolbar--right button {
        line-height: 32px; }
  .Edit .EditArea {
    width: 100vw;
    max-width: 640px;
    height: 100vh;
    background-color: #fff;
    margin: 60px auto;
    padding: 30px;
    z-index: 10000; }
    .Edit .EditArea__row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 20px; }
      .Edit .EditArea__row p {
        margin: 0 0 3px 0;
        padding: 0; }
      .Edit .EditArea__row--full {
        width: 90%; }
      .Edit .EditArea__row--half {
        width: 40%; }
        .Edit .EditArea__row--half:nth-child(odd) {
          margin-right: 15px; }
        .Edit .EditArea__row--half:nth-child(even) {
          margin-left: 15px; }
        .Edit .EditArea__row--half--quater {
          display: inline-block;
          width: 45%; }
          .Edit .EditArea__row--half--quater:nth-child(odd) {
            margin-right: 5px; }
          .Edit .EditArea__row--half--quater:nth-child(even) {
            margin-left: 5px; }
      .Edit .EditArea__row--one-third {
        width: 30%; }
    .Edit .EditArea__column {
      display: flex;
      flex-direction: column;
      width: 100%; }
    .Edit .EditArea__Deletion {
      padding-top: 20px;
      padding-right: 8px;
      float: right; }

.Toolbar {
  position: fixed;
  top: 0;
  right: 0;
  height: 60px;
  width: calc(100% - 200px);
  padding: 0px 24px;
  background-color: #fff;
  z-index: 13;
  line-height: 60px; }

.hamnav {
  position: absolute;
  cursor: pointer;
  line-height: 40px;
  float: left; }

@media (max-width: 586px) {
  .Toolbar {
    width: calc(100% - 0px);
    padding: 0px 10px;
    height: 40px;
    line-height: 0px; } }

@media (max-width: 767.98px) {
  .Toolbar {
    width: calc(100% - 0px);
    height: 40px;
    line-height: 0px; } }

@media (max-width: 768x) {
  .Toolbar {
    width: calc(100% - 54px); } }

@media (min-width: 768px) {
  .Toolbar {
    width: calc(100% - 200px); }
  .hamnav {
    display: none; } }

@charset "UTF-8";
.Navigation {
  position: fixed;
  z-index: 5;
  background-color: #262d34;
  padding: 0;
  width: 200px;
  /* 200px固定 */
  height: 100%; }
  .Navigation .Logo {
    margin: 10px 12px;
    color: #999; }
  .Navigation .User {
    margin: 22px 12px;
    width: 90%;
    font-weight: 100;
    color: #fff; }

@media (max-width: 767.98px) {
  .Navigation {
    display: none; }
    .Navigation--show {
      display: block;
      z-index: 101; } }

.FoldableList {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
  width: 100%;
  border-radius: 2px;
  background-color: #f9fafc; }
  .FoldableList--default {
    border: 1px solid #e8e8e8; }
    .FoldableList--default > .ListTitle {
      border-bottom: 1px solid #e8e8e8; }

.ListGroup {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
  width: 100%; }
  .ListGroup--default > .ListItem:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .ListGroup--underline > .ListItem {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .ListGroup--surround {
    border: 1px solid rgba(0, 0, 0, 0.125); }
    .ListGroup--surround > .ListItem:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.calender {
  background-color: #fff;
  width: 303px;
  height: auto;
  box-shadow: 1px 1px 1px 1px rgba(14, 20, 26, 0.2); }
  .calender__header {
    width: 100%;
    height: 44px;
    display: flex;
    position: relative; }
    .calender__header .calender__title {
      width: 100%;
      height: 14px;
      text-align: center;
      font-size: 14px;
      font-family: 'Noto Sans Japanese', sans-serif;
      margin-top: 12px;
      margin-bottom: 16px;
      vertical-align: middle; }
    .calender__header .calender__cancelButton {
      width: 24px;
      height: 24px;
      background: url("/client_assets/button/btn-popup-close.svg") no-repeat;
      top: 8px;
      right: 8px;
      position: absolute; }
  .calender__headerLine {
    width: 100%;
    height: 2px;
    background-color: #e8e8e8; }
  .calender__displayMonth {
    width: 100%;
    height: 36px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    position: relative; }
    .calender__displayMonth .calender__previousMonth {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 30px;
      top: 20px;
      transform: rotateY(180deg);
      background: url("/client_assets/button/btn-previous-next.svg") no-repeat; }
    .calender__displayMonth p {
      top: 20px;
      left: 128px;
      position: absolute; }
    .calender__displayMonth .calender__nextMonth {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 30px;
      top: 20px;
      background: url("/client_assets/button/btn-previous-next.svg") no-repeat; }
  .calender__grid {
    margin-left: 20px;
    margin-top: 4px;
    padding-bottom: 16px;
    width: 262.8px;
    height: auto; }
    .calender__gridCell {
      width: 32.4px;
      height: 32.4px;
      border-radius: 50%;
      text-align: center;
      line-height: 32.4px;
      float: left;
      vertical-align: bottom;
      margin-left: 6px;
      margin-top: 0px;
      display: inline-block; }
      .calender__gridCell--today {
        color: #000;
        border: solid 1px #4db5f0; }
      .calender__gridCell--selected {
        background-color: #4db5f0; }
      .calender__gridCell--DoW {
        margin-bottom: 4px; }
      .calender__gridCell:not(.calender__gridCell--DoW):not(.calender__gridCell--emply) :hover {
        background-color: #e8e8e8;
        border-radius: 50%; }

.calender::before {
  background-color: inherit;
  -webkit-filter: blur(6px);
          filter: blur(6px); }

.Cell {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer; }
  .Cell__Badge {
    width: 26px;
    height: 20px;
    border-radius: 10px;
    background-color: #f93f3f;
    margin-bottom: 0; }
    .Cell__Badge__layer {
      margin: auto;
      width: 14px;
      height: 15px;
      font-family: Lato;
      font-size: 0.75rem;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
    .Cell__Badge__transparent {
      width: 26px;
      background-color: transparent; }

.AccomodationTaxButton {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 16px 0; }
  .AccomodationTaxButton .AccomodationTaxDialog table {
    width: 95%; }
    .AccomodationTaxButton .AccomodationTaxDialog table tbody tr {
      height: 32px; }
  .AccomodationTaxButton .AccomodationTaxDialog input[type="text"], .AccomodationTaxButton .AccomodationTaxDialog input[type="number"] {
    border: none;
    border-bottom: solid 1px #A2A2A2; }
  .AccomodationTaxButton .AccomodationTaxDialog__Buttons {
    padding-top: 12px; }
    .AccomodationTaxButton .AccomodationTaxDialog__Buttons button {
      margin: 0 6px; }
  .AccomodationTaxButton .AccomodationTaxDialog textarea {
    resize: none;
    border: 2px solid #ddd; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.MonthlyView {
  position: relative; }
  .MonthlyView--1 {
    width: calc(240px + 119px * 1); }
  .MonthlyView--2 {
    width: calc(240px + 119px * 2); }
  .MonthlyView--3 {
    width: calc(240px + 119px * 3); }
  .MonthlyView--4 {
    width: calc(240px + 119px * 4); }
  .MonthlyView--5 {
    width: calc(240px + 119px * 5); }
  .MonthlyView--6 {
    width: calc(240px + 119px * 6); }
  .MonthlyView--7 {
    width: calc(240px + 119px * 7); }
  .MonthlyView--8 {
    width: calc(240px + 119px * 8); }
  .MonthlyView--9 {
    width: calc(240px + 119px * 9); }
  .MonthlyView--10 {
    width: calc(240px + 119px * 10); }
  .MonthlyView--11 {
    width: calc(240px + 119px * 11); }
  .MonthlyView--12 {
    width: calc(240px + 119px * 12); }
  .MonthlyView--13 {
    width: calc(240px + 119px * 13); }
  .MonthlyView--14 {
    width: calc(240px + 119px * 14); }
  .MonthlyView--15 {
    width: calc(240px + 119px * 15); }
  .MonthlyView--16 {
    width: calc(240px + 119px * 16); }
  .MonthlyView--17 {
    width: calc(240px + 119px * 17); }
  .MonthlyView--18 {
    width: calc(240px + 119px * 18); }
  .MonthlyView--19 {
    width: calc(240px + 119px * 19); }
  .MonthlyView--20 {
    width: calc(240px + 119px * 20); }
  .MonthlyView--21 {
    width: calc(240px + 119px * 21); }
  .MonthlyView--22 {
    width: calc(240px + 119px * 22); }
  .MonthlyView--23 {
    width: calc(240px + 119px * 23); }
  .MonthlyView--24 {
    width: calc(240px + 119px * 24); }
  .MonthlyView--25 {
    width: calc(240px + 119px * 25); }
  .MonthlyView--26 {
    width: calc(240px + 119px * 26); }
  .MonthlyView--27 {
    width: calc(240px + 119px * 27); }
  .MonthlyView--28 {
    width: calc(240px + 119px * 28); }
  .MonthlyView--29 {
    width: calc(240px + 119px * 29); }
  .MonthlyView--30 {
    width: calc(240px + 119px * 30); }
  .MonthlyView--31 {
    width: calc(240px + 119px * 31); }
  .MonthlyView__Row {
    display: grid; }
    .MonthlyView__Row__Lines--1 {
      grid-template-rows: calc(60px * 1); }
    .MonthlyView__Row__Lines--2 {
      grid-template-rows: calc(60px * 2); }
    .MonthlyView__Row__Lines--3 {
      grid-template-rows: calc(60px * 3); }
    .MonthlyView__Row__Lines--4 {
      grid-template-rows: calc(60px * 4); }
    .MonthlyView__Row__Lines--5 {
      grid-template-rows: calc(60px * 5); }
    .MonthlyView__Row__Lines--6 {
      grid-template-rows: calc(60px * 6); }
    .MonthlyView__Row__Lines--7 {
      grid-template-rows: calc(60px * 7); }
    .MonthlyView__Row__Lines--8 {
      grid-template-rows: calc(60px * 8); }
    .MonthlyView__Row__Lines--9 {
      grid-template-rows: calc(60px * 9); }
    .MonthlyView__Row__Dates {
      top: 56px;
      position: -webkit-sticky;
      position: sticky;
      z-index: 3;
      background-color: #fff; }
    .MonthlyView__Row__Header {
      grid-row: 1;
      grid-gap: 0 0;
      width: 220px;
      border: solid 1px #ddd;
      font-size: 1.2rem;
      display: table;
      height: 0;
      background-color: #fff;
      left: 0;
      position: -webkit-sticky;
      position: sticky;
      z-index: 2; }
      .MonthlyView__Row__Header__Lines--1 {
        height: calc(60px * 1); }
      .MonthlyView__Row__Header__Lines--2 {
        height: calc(60px * 2); }
      .MonthlyView__Row__Header__Lines--3 {
        height: calc(60px * 3); }
      .MonthlyView__Row__Header__Lines--4 {
        height: calc(60px * 4); }
      .MonthlyView__Row__Header__Lines--5 {
        height: calc(60px * 5); }
      .MonthlyView__Row__Header__Lines--6 {
        height: calc(60px * 6); }
      .MonthlyView__Row__Header__Lines--7 {
        height: calc(60px * 7); }
      .MonthlyView__Row__Header__Lines--8 {
        height: calc(60px * 8); }
      .MonthlyView__Row__Header__Lines--9 {
        height: calc(60px * 9); }
      .MonthlyView__Row__Header__Name {
        display: table-cell;
        vertical-align: middle;
        padding: 2px; }
    .MonthlyView__Row__Cell {
      grid-row: 1;
      width: 120px;
      border: solid 1px #ddd;
      position: relative;
      display: table;
      height: 100%; }
      .MonthlyView__Row__Cell__Date {
        text-align: center;
        font-size: 1.5rem;
        display: table-cell;
        vertical-align: middle;
        background-color: #fff;
        padding: 1px; }
        .MonthlyView__Row__Cell__Date .today {
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: #4db5f0;
          border-radius: 5px;
          margin-right: 2.5px;
          margin-bottom: 3px; }
      .MonthlyView__Row__Cell__Day {
        font-size: 1.2rem;
        margin-left: 3px; }
    .MonthlyView__Row__Stay__Container {
      z-index: 1;
      position: absolute; }
    .MonthlyView__Row__Stay {
      display: block;
      height: 50px;
      line-height: 50px;
      margin-top: 4px;
      margin-right: 4px;
      margin-left: calc(120px / 2);
      vertical-align: middle;
      width: 0;
      cursor: pointer;
      overflow: hidden; }
      .MonthlyView__Row__Stay--0 {
        width: 118px;
        border-right: transparent; }
      .MonthlyView__Row__Stay--1 {
        width: calc(118px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--2 {
        width: calc(238px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--3 {
        width: calc(358px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--4 {
        width: calc(478px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--5 {
        width: calc(598px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--6 {
        width: calc(718px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--7 {
        width: calc(838px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--8 {
        width: calc(958px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--9 {
        width: calc(1078px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--10 {
        width: calc(1198px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--11 {
        width: calc(1318px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--12 {
        width: calc(1438px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--13 {
        width: calc(1558px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--14 {
        width: calc(1678px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--15 {
        width: calc(1798px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--16 {
        width: calc(1918px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--17 {
        width: calc(2038px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--18 {
        width: calc(2158px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--19 {
        width: calc(2278px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--20 {
        width: calc(2398px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--21 {
        width: calc(2518px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--22 {
        width: calc(2638px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--23 {
        width: calc(2758px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--24 {
        width: calc(2878px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--25 {
        width: calc(2998px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--26 {
        width: calc(3118px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--27 {
        width: calc(3238px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--28 {
        width: calc(3358px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--29 {
        width: calc(3478px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--30 {
        width: calc(3598px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--31 {
        width: calc(3718px);
        border-right: transparent; }
      .MonthlyView__Row__Stay__SiteReferer {
        width: 14px;
        height: 14px;
        display: inline-block;
        object-fit: contain;
        background-size: contain;
        border: none;
        margin-left: 10px;
        vertical-align: middle; }
      .MonthlyView__Row__Stay__Name {
        padding-left: 3px; }
      .MonthlyView__Row__Stay__Status--check_in {
        background-color: #fdf3ea;
        color: #F67806; }
      .MonthlyView__Row__Stay__Status--staying {
        background-color: #e5f8f3;
        color: #00BA8A; }
      .MonthlyView__Row__Stay__Status--check_out {
        background-color: #e6eaf1;
        color: #0A2D73; }
      .MonthlyView__Row__Stay__Status--cancel {
        background-color: #e8e8e8;
        color: #818181; }
      .MonthlyView__Row__Stay__Status--unknown {
        background-color: #e8e8e8;
        color: #818181; }
      .MonthlyView__Row__Stay__Status--cleaning {
        background-color: #e8e8e8;
        color: #818181;
        margin-left: 1px; }
        .MonthlyView__Row__Stay__Status--cleaning a {
          color: #818181;
          text-decoration: none; }
      .MonthlyView__Row__Stay__FromLastMonth {
        margin-left: calc(120px / 2 * -1);
        text-align: center;
        position: absolute; }
        .MonthlyView__Row__Stay__FromLastMonth--1 {
          top: calc(60px * 1); }
        .MonthlyView__Row__Stay__FromLastMonth--2 {
          top: calc(60px * 2); }
        .MonthlyView__Row__Stay__FromLastMonth--3 {
          top: calc(60px * 3); }
        .MonthlyView__Row__Stay__FromLastMonth--4 {
          top: calc(60px * 4); }
        .MonthlyView__Row__Stay__FromLastMonth--5 {
          top: calc(60px * 5); }
        .MonthlyView__Row__Stay__FromLastMonth--6 {
          top: calc(60px * 6); }
        .MonthlyView__Row__Stay__FromLastMonth--7 {
          top: calc(60px * 7); }
        .MonthlyView__Row__Stay__FromLastMonth--8 {
          top: calc(60px * 8); }
        .MonthlyView__Row__Stay__FromLastMonth--9 {
          top: calc(60px * 9); }
      .MonthlyView__Row__Stay__ToNextMonth--1 {
        width: calc(120px * 1 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--2 {
        width: calc(120px * 2 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--3 {
        width: calc(120px * 3 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--4 {
        width: calc(120px * 4 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--5 {
        width: calc(120px * 5 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--6 {
        width: calc(120px * 6 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--7 {
        width: calc(120px * 7 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--8 {
        width: calc(120px * 8 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--9 {
        width: calc(120px * 9 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--10 {
        width: calc(120px * 10 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--11 {
        width: calc(120px * 11 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--12 {
        width: calc(120px * 12 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--13 {
        width: calc(120px * 13 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--14 {
        width: calc(120px * 14 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--15 {
        width: calc(120px * 15 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--16 {
        width: calc(120px * 16 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--17 {
        width: calc(120px * 17 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--18 {
        width: calc(120px * 18 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--19 {
        width: calc(120px * 19 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--20 {
        width: calc(120px * 20 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--21 {
        width: calc(120px * 21 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--22 {
        width: calc(120px * 22 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--23 {
        width: calc(120px * 23 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--24 {
        width: calc(120px * 24 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--25 {
        width: calc(120px * 25 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--26 {
        width: calc(120px * 26 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--27 {
        width: calc(120px * 27 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--28 {
        width: calc(120px * 28 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--29 {
        width: calc(120px * 29 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--30 {
        width: calc(120px * 30 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--31 {
        width: calc(120px * 31 - 120px / 2);
        border-right: none; }

.MoneyInput {
  display: inline-block;
  width: 100%; }
  .MoneyInput input {
    font-family: 'Lato', sans-serif;
    font-size: 1.25rem;
    height: 35px; }

#TaskList .condition__rooms {
  width: 240px;
  margin: 18px 8px; }

#TaskList .column--status {
  width: 8%; }

#TaskList .column--room_name {
  width: 22%; }

#TaskList .column--title {
  width: 30%; }

#TaskList .column--user_name {
  width: 14%; }

#TaskList .column--updated_at {
  width: 16%; }

#TaskList .column--action {
  width: 10%; }

.LocalArea {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 12px; }
  .LocalArea--left {
    margin-left: 32px;
    margin-right: auto; }
    .LocalArea--left .Button {
      margin: 18px 12px 18px 2px; }
  .LocalArea--right {
    margin-left: auto;
    margin-right: 32px; }
    .LocalArea--right .Button {
      margin: 18px 12px 18px 2px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#ConfigHotels .Toolbar__Hotel {
  height: 60px; }
  #ConfigHotels .Toolbar__Hotel .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

@media (max-width: 767.98px) {
  #ConfigHotels .Toolbar__Hotel {
    height: 40px; }
    #ConfigHotels .Toolbar__Hotel .title {
      line-height: 40px;
      font-size: 1rem; } }

#ConfigHotels .Hotels {
  height: 100%; }
  #ConfigHotels .Hotels__Title {
    padding-left: 24px;
    display: inline-block;
    margin-right: 12px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }
  #ConfigHotels .Hotels__List {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: stretch; }
    #ConfigHotels .Hotels__List .Item {
      width: 100%; }
    #ConfigHotels .Hotels__List--empty {
      width: 100%;
      height: 50%;
      /* fallback */
      height: 50vh;
      text-align: center;
      display: table; }
      #ConfigHotels .Hotels__List--empty .inner {
        display: table-cell;
        vertical-align: middle; }
        #ConfigHotels .Hotels__List--empty .inner .message {
          font-family: 'Noto Sans Japanese', sans-serif;
          font-size: 1.28rem;
          font-weight: 200;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #a2a2a2;
          margin-bottom: 20px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.SettingInfo__Toolbar {
  height: 60px; }
  .SettingInfo__Toolbar .back {
    position: absolute;
    line-height: 60px;
    vertical-align: middle; }
    .SettingInfo__Toolbar .back a {
      color: #0e141a; }
  .SettingInfo__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

@media (max-width: 767.98px) {
  .SettingInfo__Toolbar {
    height: 40px; }
    .SettingInfo__Toolbar .back {
      position: absolute;
      float: left;
      line-height: 36px;
      margin-left: 30px; }
    .SettingInfo__Toolbar .title {
      line-height: 40px;
      font-size: 1rem; } }

.SettingInfo__NavigationTabs {
  padding: 20px 25px; }
  .SettingInfo__NavigationTabs .title {
    margin-top: 30px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }
  .SettingInfo__NavigationTabs .settingInfoForm {
    margin-top: 22px; }
    .SettingInfo__NavigationTabs .settingInfoForm .imageContainer {
      float: left;
      width: 180px;
      margin-right: 50px; }
    .SettingInfo__NavigationTabs .settingInfoForm .propertyImage {
      width: 180px;
      position: relative;
      height: 172px; }
      .SettingInfo__NavigationTabs .settingInfoForm .propertyImage__preview {
        opacity: 1.0; }
      .SettingInfo__NavigationTabs .settingInfoForm .propertyImage__fileInput {
        position: absolute;
        top: 40px;
        left: 34px;
        opacity: 0.0; }
    .SettingInfo__NavigationTabs .settingInfoForm .propertyImage:hover > .propertyImage__preview {
      opacity: 0.5; }
    .SettingInfo__NavigationTabs .settingInfoForm .propertyImage:hover > .propertyImage__fileInput {
      opacity: 0.7; }
    .SettingInfo__NavigationTabs .settingInfoForm .basicInfo .formInput1 {
      margin-bottom: 22px; }
    .SettingInfo__NavigationTabs .settingInfoForm .basicInfo .formInput2 {
      margin-bottom: 22px; }
    .SettingInfo__NavigationTabs .settingInfoForm .beds24InfoForm {
      margin-top: 22px; }
      .SettingInfo__NavigationTabs .settingInfoForm .beds24InfoForm__Row {
        margin-left: 0; }
      .SettingInfo__NavigationTabs .settingInfoForm .beds24InfoForm .formInput {
        margin-bottom: 22px; }
      .SettingInfo__NavigationTabs .settingInfoForm .beds24InfoForm .bed24FormInput {
        margin-top: 23px; }
  .SettingInfo__NavigationTabs .footButtons {
    margin-top: 40px; }
    .SettingInfo__NavigationTabs .footButtons .col {
      padding-left: 0; }
    .SettingInfo__NavigationTabs .footButtons .footButton__delete {
      float: right; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#SettingTablet .SettingTablet__Toolbar {
  height: 60px; }
  #SettingTablet .SettingTablet__Toolbar .back {
    position: absolute;
    line-height: 60px;
    vertical-align: middle; }
    #SettingTablet .SettingTablet__Toolbar .back a {
      color: #0e141a; }
  #SettingTablet .SettingTablet__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

@media (max-width: 767.98px) {
  #SettingTablet .SettingTablet__Toolbar {
    height: 40px; }
    #SettingTablet .SettingTablet__Toolbar .back {
      position: absolute;
      float: left;
      line-height: 36px;
      margin-left: 30px; }
    #SettingTablet .SettingTablet__Toolbar .title {
      line-height: 40px;
      font-size: 1rem; } }

#SettingTablet .SettingTablet__NavigationTabs {
  padding: 20px 25px; }
  #SettingTablet .SettingTablet__NavigationTabs .title {
    margin-top: 30px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300;
    display: flex; }
  #SettingTablet .SettingTablet__NavigationTabs .previewButton {
    width: 24px;
    height: 24px;
    background: url("/client_assets/button/btn-hint.svg") no-repeat;
    margin-left: 8px;
    margin-top: 4px;
    cursor: pointer; }
  #SettingTablet .SettingTablet__NavigationTabs ul.NavigationLinks {
    list-style-type: none;
    padding: 0; }
    #SettingTablet .SettingTablet__NavigationTabs ul.NavigationLinks li {
      padding: 0;
      margin-bottom: 15px; }
    #SettingTablet .SettingTablet__NavigationTabs ul.NavigationLinks li > a {
      color: #4db5f0; }
  #SettingTablet .SettingTablet__NavigationTabs .BasicGroup {
    margin-bottom: 40px; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .basicForm .icon {
      vertical-align: middle; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .basicLabel {
      margin-bottom: 8px; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .basicLabel2 {
      vertical-align: middle;
      line-height: 60px; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .basicNote {
      color: #818181;
      margin-bottom: 8px; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .Cell__FileUpload .ImgIcon {
      vertical-align: middle;
      margin: 0 5px; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .Cell__FileUpload .FileUpload {
      display: inline-block;
      vertical-align: middle;
      height: 34px; }
      #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .Cell__FileUpload .FileUpload .fileInput__label {
        display: none; }
      #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .Cell__FileUpload .FileUpload .fileInput__message {
        display: inline; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .mapUrl {
      padding-right: 20px;
      width: 260px; }
  #SettingTablet .SettingTablet__NavigationTabs .ManualGroup {
    margin-bottom: 40px; }
    #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .ListItem {
      padding-right: 0; }
    #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .label {
      vertical-align: middle;
      line-height: 40px; }
      #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .label .icon {
        margin: auto 10px;
        vertical-align: middle; }
    #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .more {
      float: right;
      margin: 13px 10px; }
      #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .more .editButton {
        display: inline-block;
        padding: 0 10px; }
      #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .more .deleteButton {
        display: inline-block;
        padding: 0 3px; }
    #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .sort {
      float: right;
      margin: 13px 10px; }
      #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .sort button {
        cursor: move;
        border: none;
        background: none; }
    #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .addManual {
      margin: 5px 0; }
  #SettingTablet .SettingTablet__NavigationTabs .TerminalGroup {
    margin-bottom: 40px; }
    #SettingTablet .SettingTablet__NavigationTabs .TerminalGroup .logoutCell {
      float: right;
      display: none; }
      #SettingTablet .SettingTablet__NavigationTabs .TerminalGroup .logoutCell .logoutTerminal {
        margin-right: 0; }

#SettingTablet .ListItem:hover .logoutCell {
  display: block; }

#SettingTablet .TabletPreviewDialog {
  width: 726px;
  height: 516px;
  background-color: #fff;
  margin: 10vh auto 0 auto;
  z-index: 100001; }
  #SettingTablet .TabletPreviewDialog__image {
    display: flex;
    width: 100%;
    height: auto;
    padding-top: 96px; }
    #SettingTablet .TabletPreviewDialog__imageTop {
      width: 210px;
      height: 280px;
      background: url("/client_assets/tablet/top.jpg") no-repeat;
      background-size: contain;
      margin-left: 24px; }
    #SettingTablet .TabletPreviewDialog__imageSelectLang {
      width: 210px;
      height: 280px;
      background: url("/client_assets/tablet/selectLang.jpg") no-repeat;
      background-size: contain;
      margin-left: 24px; }
    #SettingTablet .TabletPreviewDialog__imageMenu {
      width: 210px;
      height: 280px;
      background: url("/client_assets/tablet/menu.jpg") no-repeat;
      background-size: contain;
      margin-left: 24px; }
  #SettingTablet .TabletPreviewDialog__comments--top {
    font-size: 14px;
    font-family: 'Noto Sans Japanese', sans-serif;
    position: absolute;
    margin-top: -44px;
    margin-left: 87px; }
  #SettingTablet .TabletPreviewDialog__comments--topLogo {
    font-size: 14px;
    font-family: 'Noto Sans Japanese', sans-serif;
    position: absolute;
    margin-top: 298px;
    margin-left: 87px; }
  #SettingTablet .TabletPreviewDialog__comments--selectLangLogo {
    font-size: 14px;
    font-family: 'Noto Sans Japanese', sans-serif;
    position: absolute;
    margin-top: -44px;
    margin-left: 307px; }
  #SettingTablet .TabletPreviewDialog__comments--menu {
    font-size: 14px;
    font-family: 'Noto Sans Japanese', sans-serif;
    position: absolute;
    margin-top: 298px;
    margin-left: 534px; }
  #SettingTablet .TabletPreviewDialog button {
    width: 88px;
    height: 34px;
    margin-right: 24px;
    margin-top: 86px;
    float: right; }

#SettingTablet .ManualDialog {
  width: 450px;
  background-color: #fff;
  margin: 10vh auto 0 auto;
  z-index: 100001;
  padding: 22px;
  font-size: 0.9rem; }
  #SettingTablet .ManualDialog .title {
    margin-top: 0; }
  #SettingTablet .ManualDialog .required {
    color: #ff0000; }
  #SettingTablet .ManualDialog__pdf {
    margin-bottom: 24px; }
  #SettingTablet .ManualDialog__title {
    margin-bottom: 24px; }
    #SettingTablet .ManualDialog__title p {
      margin-bottom: 5px; }
    #SettingTablet .ManualDialog__title .LabeledInputField input[type=text] {
      width: 110px;
      margin: auto 0 auto 0; }
  #SettingTablet .ManualDialog__keywords {
    margin-bottom: 24px; }
    #SettingTablet .ManualDialog__keywords p {
      margin-bottom: 5px; }
    #SettingTablet .ManualDialog__keywords .help {
      border-radius: 2px;
      background-color: #e6eaf1;
      font-size: 0.9rem;
      padding: 4px;
      margin-left: 10px; }
    #SettingTablet .ManualDialog__keywords .LabeledInputField input[type=text] {
      width: 320px;
      margin: auto 0 auto 0; }
  #SettingTablet .ManualDialog .LabeledInputField {
    border-bottom: solid 1px #a2a2a2;
    margin: 10px auto 10px auto;
    padding-bottom: 8px; }
    #SettingTablet .ManualDialog .LabeledInputField span {
      background-color: #e8e8e8;
      padding: 2px 4px 2px 4px;
      border-radius: 2px;
      font-size: 0.9rem; }
    #SettingTablet .ManualDialog .LabeledInputField input[type=text] {
      border: none;
      margin-left: 5px; }
      #SettingTablet .ManualDialog .LabeledInputField input[type=text]:focus {
        outline: 0; }
  #SettingTablet .ManualDialog__action {
    width: 100%;
    height: 30px; }
    #SettingTablet .ManualDialog__action .buttons {
      float: right; }
      #SettingTablet .ManualDialog__action .buttons button {
        margin: 0 6px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#SettingSensor .SettingSensor__Toolbar {
  height: 60px; }
  #SettingSensor .SettingSensor__Toolbar .back {
    position: absolute;
    line-height: 60px;
    vertical-align: middle; }
    #SettingSensor .SettingSensor__Toolbar .back a {
      color: #0e141a; }
  #SettingSensor .SettingSensor__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

@media (max-width: 767.98px) {
  #SettingSensor .SettingSensor__Toolbar .back {
    position: absolute;
    margin: 0px 50px; } }

#SettingSensor .SettingSensor__NavigationTabs {
  padding: 20px 25px; }
  #SettingSensor .SettingSensor__NavigationTabs .title {
    margin-top: 30px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }
  #SettingSensor .SettingSensor__NavigationTabs .setupNote .ListTitle {
    height: 50px;
    vertical-align: middle;
    line-height: 30px; }
  #SettingSensor .SettingSensor__NavigationTabs .alertMail button {
    margin-top: 20px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#SmtpSetting .SmtpSetting__Toolbar {
  height: 60px; }
  #SmtpSetting .SmtpSetting__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

#SmtpSetting .Edit .DeleteButton {
  margin: 20px 8px 0 auto; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#AutoActionMail .AutoActionMail__Toolbar {
  height: 60px; }
  #AutoActionMail .AutoActionMail__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

#AutoActionMail .Edit .DeleteButton {
  margin: 20px 8px 0 auto; }

#AutoActionConfig .status_list input[type="radio"] {
  margin-right: 5px; }

#AutoActionConfig .status_list label {
  margin-right: 20px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#AutoActionTask .AutoActionTask__Toolbar {
  height: 60px; }
  #AutoActionTask .AutoActionTask__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

#AutoActionTask .Edit .DeleteButton {
  margin: 20px 8px 0 auto; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.CheckInManagement__Toolbar {
  height: 60px; }
  .CheckInManagement__Toolbar .back {
    position: absolute;
    line-height: 60px;
    vertical-align: middle; }
    .CheckInManagement__Toolbar .back a {
      color: #0e141a; }
  .CheckInManagement__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

@media (max-width: 767.98px) {
  .CheckInManagement__Toolbar {
    height: 40px; }
    .CheckInManagement__Toolbar .back {
      position: absolute;
      float: left;
      line-height: 36px;
      margin-left: 30px; }
    .CheckInManagement__Toolbar .title {
      line-height: 40px;
      font-size: 1rem; } }

#CheckInManagement .LocalArea .searchKeyword {
  height: 40px;
  margin: 18px 8px 18px 18px; }

#CheckInManagement .LocalArea .searchHouse {
  width: 240px;
  margin: 18px 8px; }

#CheckInManagement .LocalArea .searchCheckIn {
  width: 110px;
  height: 38px; }

#CheckInManagement .LocalArea .Button {
  margin: 18px 12px; }

#CheckInManagement .LocalArea .localArea--right {
  margin-left: auto;
  margin-right: 32px; }
  #CheckInManagement .LocalArea .localArea--right .Button {
    margin: 18px 12px 18px 2px; }

#CheckInManagement .LocalArea:after {
  clear: both; }

#CheckInManagement .Reservations {
  height: 100%;
  margin-bottom: 48px; }
  #CheckInManagement .Reservations__Title {
    padding-left: 24px;
    display: inline-block;
    margin-right: 12px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }
  #CheckInManagement .Reservations__List {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: stretch; }
    #CheckInManagement .Reservations__List .Item {
      width: 100%;
      padding: 24px; }
      #CheckInManagement .Reservations__List .Item .Cell {
        padding: 1px; }
      #CheckInManagement .Reservations__List .Item__Name {
        width: 50%; }
      #CheckInManagement .Reservations__List .Item__Hotel {
        width: 50%; }
      #CheckInManagement .Reservations__List .Item__Period {
        width: 100%; }
        #CheckInManagement .Reservations__List .Item__Period p {
          margin: 0; }
      #CheckInManagement .Reservations__List .Item__Referer {
        width: 50%; }
      #CheckInManagement .Reservations__List .Item__Action {
        width: 50%; }
        #CheckInManagement .Reservations__List .Item__Action button {
          margin: auto 5px; }
      @media (min-width: 1200px) {
        #CheckInManagement .Reservations__List .Item__Name {
          width: 24%; }
        #CheckInManagement .Reservations__List .Item__Hotel {
          width: 24%; }
        #CheckInManagement .Reservations__List .Item__Period {
          width: 30%; }
        #CheckInManagement .Reservations__List .Item__Referer {
          width: 7%; }
        #CheckInManagement .Reservations__List .Item__Action {
          width: 15%; }
          #CheckInManagement .Reservations__List .Item__Action button {
            margin: auto 5px; } }
    #CheckInManagement .Reservations__List--empty {
      width: 100%;
      height: 50%;
      /* fallback */
      height: 50vh;
      text-align: center;
      display: table; }
      #CheckInManagement .Reservations__List--empty .inner {
        display: table-cell;
        vertical-align: middle; }
        #CheckInManagement .Reservations__List--empty .inner .message {
          font-family: 'Noto Sans Japanese', sans-serif;
          font-size: 1.28rem;
          font-weight: 200;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #a2a2a2;
          margin-bottom: 20px; }

#CheckInManagement .CheckInManagement__Form {
  padding: 20px 25px; }
  #CheckInManagement .CheckInManagement__Form .row {
    margin-bottom: 22px; }
  #CheckInManagement .CheckInManagement__Form .imageContainer {
    float: left;
    width: 180px;
    margin-right: 50px; }
  #CheckInManagement .CheckInManagement__Form .propertyImage {
    width: 180px;
    position: relative;
    height: 172px; }
    #CheckInManagement .CheckInManagement__Form .propertyImage__preview {
      opacity: 1.0; }
    #CheckInManagement .CheckInManagement__Form .propertyImage__fileInput {
      position: absolute;
      top: 40px;
      left: 34px;
      opacity: 0.0; }
  #CheckInManagement .CheckInManagement__Form .propertyImage:hover > .propertyImage__preview {
    opacity: 0.5; }
  #CheckInManagement .CheckInManagement__Form .propertyImage:hover > .propertyImage__fileInput {
    opacity: 0.7; }
  #CheckInManagement .CheckInManagement__Form .basicInfo .formInput1 {
    margin-bottom: 22px; }
  #CheckInManagement .CheckInManagement__Form .basicInfo .formInput2 {
    margin-bottom: 22px; }

@charset "UTF-8";
.dataProtection {
  margin: 40px auto 40px auto;
  padding: 30px 40px 40px 40px;
  background: #f9fafc; }
  @media (max-width: 769.9px) {
    .dataProtection {
      width: auto; } }
  @media (min-width: 770px) {
    .dataProtection {
      width: 60%; } }

.guestform {
  position: relative;
  display: flex;
  align-items: stretch;
  padding: 0 28px;
  margin: 0 auto;
  width: 960px; }
  .guestform__label {
    position: relative;
    width: 260px;
    padding-left: 20px;
    border-left: solid 2px #e8e8e8; }
    .guestform__label .circle {
      position: absolute;
      display: inline-block;
      left: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #000; }
    .guestform__label .guestMember {
      margin-top: -5px; }
  .guestform .labelGender {
    margin-left: 3px;
    margin-right: 8px; }
  .guestform__delete {
    cursor: pointer;
    width: 28px;
    height: 28px;
    position: absolute;
    right: 15px;
    top: -10px;
    background-color: #fff;
    border: solid 1px #e8e8e8;
    border-radius: 50%; }
    .guestform__delete:before {
      content: '\D7';
      color: #a2a2a2;
      font-size: 1.15rem;
      padding: 5px; }
    .guestform__delete .deleteDialog {
      position: absolute;
      top: -140px;
      left: -128px;
      width: 256px;
      height: 136px;
      background-color: #fff;
      -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
              filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
      border: solid 1px #ddd;
      padding: 18px 12px 18px 12px;
      font-size: 0.9rem; }
      .guestform__delete .deleteDialog:after {
        content: '';
        position: absolute;
        border-top: 12px solid #fff;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        border-bottom: 12px solid transparent;
        bottom: -24px;
        left: 50%; }
      .guestform__delete .deleteDialog p {
        margin-bottom: 3px; }
      .guestform__delete .deleteDialog .buttonGroup {
        margin-top: 20px; }
        .guestform__delete .deleteDialog .buttonGroup button {
          margin-right: 6px; }
  .guestform__finish {
    position: relative;
    width: 260px;
    padding-left: 20px;
    margin-bottom: 20px;
    padding-top: 0; }
    .guestform__finish .circle {
      position: absolute;
      display: inline-block;
      left: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #e8e8e8; }
  .guestform__inputform {
    width: 700px;
    padding: 0 0 20px 0;
    font-size: 0.8rem; }
    .guestform__inputform .inner {
      border-top: solid 1px #e8e8e8;
      border-bottom: solid 1px #e8e8e8;
      border-right: solid 1px #e8e8e8;
      border-left: solid 2px #0e141a;
      padding: 15px 25px; }
      .guestform__inputform .inner .form1 {
        margin: 10px 0; }
      .guestform__inputform .inner .form2 {
        margin: 10px 0; }
      .guestform__inputform .inner .fileInput {
        margin-top: 10px; }
      .guestform__inputform .inner .dropdown {
        display: block;
        width: 80%; }
      .guestform__inputform .inner .image {
        max-width: 85%; }
  .guestform__noform {
    width: 700px;
    padding: 0px 0 40px 0;
    border: none; }
    .guestform__noform .buttonGroup button {
      margin-right: 12px; }

.checkinComp {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(38, 45, 52, 0.5);
  z-index: 1000; }
  .checkinComp__background {
    width: 403px;
    height: 278px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  .checkinComp__image {
    width: 100%;
    text-align: center;
    padding: 40px 0; }
  .checkinComp__imageExpired {
    width: 100%;
    text-align: center;
    padding: 40px 0; }
  .checkinComp__messages {
    text-align: center; }
    .checkinComp__messages--en {
      font-size: 20px;
      font-family: Lato, sans-serif; }
    .checkinComp__messages--jp {
      font-size: 14px;
      font-family: 'Noto Sans Japanese', sans-serif; }

@media (max-width: 991.98px) {
  .guestform {
    display: block;
    padding: 0 10px;
    width: auto; }
    .guestform__label {
      padding-bottom: 20px;
      border: none; }
    .guestform__inputform {
      width: 100%; }
    .guestform__noform {
      width: 100%; } }

.dailyProcess {
  padding-left: 25px; }
  .dailyProcess .LocalArea .searchCheckIn {
    width: 110px;
    height: 34px; }
  .dailyProcess .LocalArea .searchHouse {
    width: 240px;
    margin: 18px 8px; }
  .dailyProcess .LocalArea .Button {
    margin: 18px 5px; }
  .dailyProcess .LocalArea:after {
    clear: both; }
  .dailyProcess .inputs .Dropdown {
    width: 150px; }
  .dailyProcess .inputs h3 {
    width: 500px;
    border-bottom: solid 1px #aaa; }
  .dailyProcess .inputs .required {
    font-size: 0.7rem;
    color: #db2d26;
    padding-left: 3px; }
  .dailyProcess .inputs .closeButton {
    color: #fff;
    background-color: #000;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 0.3rem;
    text-decoration: none; }
  .dailyProcess .inputs table {
    width: 500px; }
    .dailyProcess .inputs table tr {
      height: 45px; }
    .dailyProcess .inputs table th {
      width: 160px; }
    .dailyProcess .inputs table td input {
      width: 120px;
      margin-right: 6px; }
    .dailyProcess .inputs table td .price {
      font-size: 1.5rem;
      margin-right: 3px; }
    .dailyProcess .inputs table td.one {
      width: 280px; }
    .dailyProcess .inputs table td.two {
      width: 170px; }
    .dailyProcess .inputs table td.three {
      width: 160px; }
      .dailyProcess .inputs table td.three--1 {
        width: 200px; }
        .dailyProcess .inputs table td.three--1 .Dropdown {
          width: 95%; }
      .dailyProcess .inputs table td.three--2 {
        width: 120px; }
        .dailyProcess .inputs table td.three--2 input {
          width: 90px; }
      .dailyProcess .inputs table td.three--close {
        width: 20px; }
    .dailyProcess .inputs table td .status {
      font-size: 0.75rem;
      color: #fff;
      padding: 4px;
      border-radius: 8px;
      margin-right: 4px; }
      .dailyProcess .inputs table td .status--none {
        background-color: #c00; }
      .dailyProcess .inputs table td .status--saved {
        background-color: #888; }
      .dailyProcess .inputs table td .status--closed {
        background-color: #4DB5F0; }
  .dailyProcess .inputs textarea {
    width: 500px;
    height: 120px; }
  .dailyProcess .actions {
    width: 500px; }
    .dailyProcess .actions button {
      float: right;
      margin-top: 24px;
      margin-bottom: 24px;
      margin-right: 12px; }

.monthlyProcess {
  padding-top: 5px;
  padding-left: 25px; }
  .monthlyProcess .LocalArea .searchCheckIn {
    width: 110px;
    height: 34px; }
  .monthlyProcess .LocalArea .searchHouse {
    width: 240px;
    margin: 18px 8px; }
  .monthlyProcess .LocalArea .Button {
    margin: 18px 5px; }
  .monthlyProcess .LocalArea:after {
    clear: both; }
  .monthlyProcess .display {
    margin: 18px 0; }
    .monthlyProcess .display .displayTarget {
      margin-bottom: 5px; }
      .monthlyProcess .display .displayTarget .targetDate {
        background-color: #000;
        color: #fff;
        padding: 8px;
        margin-right: 12px; }
      .monthlyProcess .display .displayTarget .targetHotel {
        font-size: 1.4rem;
        margin-right: 12px; }
      .monthlyProcess .display .displayTarget .status {
        font-size: 0.75rem;
        color: #fff;
        padding: 4px;
        border-radius: 8px;
        margin-right: 12px; }
        .monthlyProcess .display .displayTarget .status--none {
          background-color: #c00; }
        .monthlyProcess .display .displayTarget .status--saved {
          background-color: #888; }
        .monthlyProcess .display .displayTarget .status--closed {
          background-color: #4DB5F0; }
  .monthlyProcess .inputs .closeButton {
    color: #fff;
    background-color: #000;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 0.3rem;
    margin-left: 6px; }
    .monthlyProcess .inputs .closeButton:hover {
      text-decoration: none; }
  .monthlyProcess .inputs .Dropdown {
    width: 150px; }
  .monthlyProcess .inputs h3 {
    width: 100%;
    border-bottom: solid 1px #aaa; }
  .monthlyProcess .inputs table tr {
    height: 38px; }
  .monthlyProcess .inputs table td .Dropdown {
    width: 95%; }
  .monthlyProcess .inputs table td .MoneyInput {
    width: 130px;
    margin-right: 4px; }
  .monthlyProcess .inputs table td .DatePicker {
    padding: 8px;
    width: 120px;
    height: 36px; }
  .monthlyProcess .inputs table td input {
    margin-right: 6px; }
    .monthlyProcess .inputs table td input .price {
      font-size: 1.5rem;
      margin-right: 3px; }
  .monthlyProcess .inputs textarea {
    width: 100%;
    height: 120px; }
  .monthlyProcess .actions {
    width: 100%; }
    .monthlyProcess .actions button {
      float: right;
      margin-top: 24px;
      margin-bottom: 24px;
      margin-right: 12px; }
  .monthlyProcess .summary {
    width: 100%; }
    .monthlyProcess .summary th {
      width: 220px; }
    .monthlyProcess .summary td {
      width: 120px; }
      .monthlyProcess .summary td ul {
        padding-left: 0; }
        .monthlyProcess .summary td ul li {
          list-style-type: none;
          text-align: right; }
        .monthlyProcess .summary td ul li.total {
          list-style-type: none;
          text-align: right;
          border-top: solid 1px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#AccountTitles .ListGroup .ListItem .position {
  width: 62px; }

#AccountTitles .ListGroup .ListItem .titleGroupName {
  width: 160px; }

#AccountTitles .ListGroup .ListItem .name {
  width: 240px; }

#AccountTitles .ListGroup .ListItem .action {
  width: 80px; }

/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#AccountTitleDetails .ListGroup .ListItem .position {
  width: 62px; }

#AccountTitleDetails .ListGroup .ListItem .titleGroupName {
  width: 160px; }

#AccountTitleDetails .ListGroup .ListItem .name {
  width: 240px; }

#AccountTitleDetails .ListGroup .ListItem .cost_type {
  width: 160px; }

#AccountTitleDetails .ListGroup .ListItem .action {
  width: 80px; }

.segment input {
  display: none; }

.segment label {
  display: block;
  float: left;
  cursor: pointer;
  width: 80px;
  margin: 0;
  padding: 12px 5px;
  border-right: 1px solid #abb2b7;
  background: #bdc3c7;
  color: #555e64;
  font-size: 14px;
  text-align: center;
  line-height: 1;
  transition: .2s; }

.segment label:first-of-type {
  border-radius: 3px 0 0 3px; }

.segment label:last-of-type {
  border-right: 0px;
  border-radius: 0 3px 3px 0; }

.segment input[type="radio"]:checked + label {
  background-color: #00BA8A;
  color: #fff; }

.loginView {
  background-color: #262D34;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%; }
  .loginView__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .loginView .loginForm {
    margin: calc(50vh - 300px) auto;
    padding-top: 60px;
    width: 420px;
    height: 610px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 6px 0 rgba(14, 20, 26, 0.2); }
    .loginView .loginForm__inner {
      width: 300px;
      margin: auto; }
      .loginView .loginForm__inner__welcomeText {
        height: 24px;
        font-family: Lato sans-serif;
        font-size: 20px;
        white-space: nowrap;
        color: #0e141a;
        text-align: center; }
      .loginView .loginForm__inner__instruction {
        height: 17px;
        font-family: Lato sans-serif;
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
        color: #0e141a; }
      .loginView .loginForm__inner__inputArea {
        width: auto;
        height: 122px;
        margin-top: 40px;
        position: relative; }
        .loginView .loginForm__inner__inputArea .textInput {
          margin: 0 0 22px 0;
          width: 300px; }
      .loginView .loginForm__inner__Error {
        width: 300px;
        height: 80px;
        font-family: NotoSansCJKjp sans-serif; }
      .loginView .loginForm__inner button {
        width: 300px;
        height: 36px;
        margin: 20px 0;
        object-fit: contain;
        font-size: 14px; }
      .loginView .loginForm__inner__tryButton:disabled {
        border: solid 1px #e8e8e8;
        color: #e8e8e8; }
      .loginView .loginForm__inner__forgotText {
        height: 14px;
        font-size: 14px;
        text-align: center;
        color: #4db5f0; }

.passwordReset {
  background-color: #262D34;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 0.9rem; }
  .passwordReset__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .passwordReset .form {
    margin: calc(50vh - 300px) auto;
    padding-top: 60px;
    width: 420px;
    height: 550px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 6px 0 rgba(14, 20, 26, 0.2); }
    .passwordReset .form__inner {
      width: 300px;
      margin: auto; }
      .passwordReset .form__inner__message {
        margin: 40px 0;
        color: #0e141a; }
      .passwordReset .form__inner__sentMessage {
        color: #0e141a;
        text-align: center;
        width: 300px;
        height: 36px;
        margin: 0 60px; }
      .passwordReset .form__inner__inputArea {
        width: auto;
        height: 70px; }
        .passwordReset .form__inner__inputArea .textInput {
          width: 300px; }
      .passwordReset .form__inner button {
        width: 300px;
        height: 36px;
        margin-top: 30px; }
      .passwordReset .form__inner .backToLogin {
        margin-top: 32px; }
        .passwordReset .form__inner .backToLogin:hover {
          cursor: pointer; }
        .passwordReset .form__inner .backToLogin__icon {
          vertical-align: middle; }

.passwordResetUpdate {
  background: url("/client_assets/sample/login_bg.jpg") no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 0.9rem; }
  .passwordResetUpdate__background {
    width: 100%; }
  .passwordResetUpdate .form {
    margin: calc(50vh - 300px) auto;
    padding-top: 60px;
    width: 420px;
    height: 550px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 6px 0 rgba(14, 20, 26, 0.2); }
    .passwordResetUpdate .form__inner {
      width: 300px;
      margin: auto; }
      .passwordResetUpdate .form__inner__message {
        margin: 40px 0;
        color: #0e141a; }
      .passwordResetUpdate .form__inner__sentMessage {
        color: #0e141a;
        text-align: center;
        width: 300px;
        height: 36px; }
      .passwordResetUpdate .form__inner__inputArea {
        width: auto;
        height: 160px; }
        .passwordResetUpdate .form__inner__inputArea .textInput {
          width: 300px;
          margin-top: 20px; }
      .passwordResetUpdate .form__inner button {
        width: 300px;
        height: 36px; }
      .passwordResetUpdate .form__inner .backToLogin {
        margin-top: 32px; }
        .passwordResetUpdate .form__inner .backToLogin:hover {
          cursor: pointer; }
        .passwordResetUpdate .form__inner .backToLogin__icon {
          vertical-align: middle; }

.SnackbarView {
  width: auto;
  height: auto;
  z-index: 100001; }

