.dailyProcess {
  padding-left: 25px;

  .LocalArea {
    .searchCheckIn {
      width: 110px;
      height: 34px;
    }
    .searchHouse {
      width: 240px;
      margin: 18px 8px;
    }

    .Button {
      margin: 18px 5px;
    }

    &:after {
      clear: both;
    }
  }

  .inputs {
    .Dropdown {
      width: 150px;
    }
    h3 {
      width: 500px;
      border-bottom: solid 1px #aaa;
    }
    .required {
      font-size: 0.7rem;
      color: #db2d26;
      padding-left: 3px;
    }
    .closeButton {
      color: #fff;
      background-color: #000;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: 0.3rem;
      text-decoration: none;
    }
    table {
      width: 500px;
      tr { height: 45px; }
      th { width: 160px; }
      td {
        input {
          width: 120px;
          margin-right: 6px;
        }
        .price {
          font-size: 1.5rem;
          margin-right: 3px;
        }
        &.one {
          width: 280px;
        }
        &.two {
          width: 170px;
        }
        &.three {
          width: 160px;
          &--1 {
            width: 200px;
            .Dropdown {
              width: 95%;
            }
          }
          &--2 {
            width: 120px;
            input {
              width: 90px;
            }
          }
          &--close {
            width: 20px;
          }
        }
        .status {
          font-size: 0.75rem;

          color: #fff;
          padding: 4px;
          border-radius: 8px;
          margin-right: 4px;

          &--none {
            background-color: #c00;
          }
          &--saved {
            background-color: #888;
          }
          &--closed {
            background-color: #4DB5F0;
          }
        }
      }
    }
    textarea {
      width: 500px;
      height: 120px;
    }
  }
  .actions {
    width: 500px;
    button {
      float: right;
      margin-top: 24px;
      margin-bottom: 24px;
      margin-right: 12px;
    }
  }

}

