//Color Schemes
//Basic colors
$basic-color-white: #F9FAFC;
$basic-color-black1: #0E141A;
$basic-color-black2: #1A1C1F;
$basic-color-black3: #262D34;
$basic-color-dark-gray: #818181;
$basic-color-gray: #A2A2A2;
$basic-color-light-gray: #DDDDDD;
$basic-color-extra-light-gray: #E8E8E8;
$basic-color-light-blue: #4DB5F0;

//Accent colors
$accent-color-navy: #0A2D73;
$accent-color-light-green: #00BA8A;
$accent-color-orange: #F67806;
$accent-color-red: #DB2D26;


/* Color palette */

$greyish-brown: #454545;

/* Component styles */
@mixin overlay($z-index) {
  position: fixed;
  height: 100%;
  top:0;
  left: 0;
  z-index: $z-index;
  background-color: rgba(38, 45, 52, 0.6);
  width: 100vw;
}

@mixin dialog($width, $height) {
  background-color: #fff;
  margin: 10vh auto 0 auto;
  width: $width;
  height: $height;
  z-index: 100001;
  padding: 24px;
  opacity: 1;
}

/* Text styles */

@mixin Title-Kanji {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
}

@mixin Sub-content {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 0.85rem;
  font-weight: 300;
}

@mixin Subtitle {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1rem;
  font-weight: 300;
}

@mixin Sub-content {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1rem;
  font-weight: 300;
}

@mixin Subtitle-Kanji {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1rem;
  font-weight: 300;
}


title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a;
}

.clearfix {
  *zoom: 1;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.input-required:after {
  content:"*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px;
}
