#AutoActionConfig {
  .status_list {
    input[type="radio"] {
      margin-right: 5px;
    }
    label {
      margin-right: 20px;
    }
  }
}
