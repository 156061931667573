/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.FileInput {
  height: 34px;
  vertical-align: middle; }
  .FileInput__type {
    display: inline-block;
    height: 32px;
    padding-top: 2px;
    vertical-align: middle; }
  .FileInput__fileArea {
    height: 34px;
    border-radius: 2px;
    border: solid 1px #a2a2a2;
    padding: 0 8px; }
  .FileInput__message {
    color: #0E141A;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 0.95rem; }
  .FileInput__file {
    height: 34px;
    display: none; }
  .FileInput__trash {
    display: inline-block;
    height: 31px;
    padding-top: 3px;
    vertical-align: middle;
    cursor: pointer; }
