.ListTitle {
  cursor: pointer;
  padding: 10px 24px;

  .more {
    float: right;

    button {
      position: relative;
      width: 24px;
      height: 24px;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    svg {
      position: absolute;
      top: 0;
    }

  }

  .status {
    transform: rotate(0deg);

    &--open {
      top: -24px;
      transform: rotate(90deg);
    }
  }
}
