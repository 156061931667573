/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#AccountTitleDetails .ListGroup .ListItem .position {
  width: 62px; }

#AccountTitleDetails .ListGroup .ListItem .titleGroupName {
  width: 160px; }

#AccountTitleDetails .ListGroup .ListItem .name {
  width: 240px; }

#AccountTitleDetails .ListGroup .ListItem .cost_type {
  width: 160px; }

#AccountTitleDetails .ListGroup .ListItem .action {
  width: 80px; }
