.Cell {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  
  &__Badge {
    width: 26px;
    height: 20px;
    border-radius: 10px;
    background-color: #f93f3f;
    margin-bottom: 0;

    &__layer {
      margin: auto;
      width: 14px;
      height: 15px;
      font-family: Lato;
      font-size: 0.75rem;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
    &__transparent {
      width: 26px;
      background-color: transparent;
    }

  }
  
}
