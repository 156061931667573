
.calender {
  background-color: #fff;
  width: 303px;
  height: auto;
box-shadow: 1px 1px 1px 1px rgba(14, 20, 26, 0.2);

  &__header {
    width: 100%;
    height: 44px;
    display: flex;
    position: relative;

    .calender__title {
      width: 100%;
      height: 14px;
      text-align: center;
      font-size: 14px;
      font-family: 'Noto Sans Japanese', sans-serif;
      margin-top: 12px;
      margin-bottom: 16px;
      vertical-align: middle;
    }

    .calender__cancelButton {
      width: 24px;
      height: 24px;
      background: url('/client_assets/button/btn-popup-close.svg') no-repeat;
      top: 8px;
      right: 8px;
      position: absolute;
    }
  }

  &__headerLine {
    width: 100%;
    height: 2px;
    background-color: #e8e8e8;
  }

  &__displayMonth {
    width: 100%;
    height: 36px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    position: relative;

    .calender__previousMonth {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 30px;
      top: 20px;
      transform: rotateY(180deg);
      background: url('/client_assets/button/btn-previous-next.svg') no-repeat;
    }

    p {
      top: 20px;
      left:128px;
      position: absolute;
    }

    .calender__nextMonth {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 30px;
      top: 20px;

      background: url('/client_assets/button/btn-previous-next.svg') no-repeat;

    }
  }



  &__grid {
    margin-left: 20px;
    margin-top: 4px;
    padding-bottom: 16px;
    width: 262.8px;
    height: auto;

    &Cell {

      width: 32.4px;
      height: 32.4px;
      border-radius: 50%;
      text-align: center;
      line-height: 32.4px;
      float: left;
      vertical-align:bottom;
      margin-left: 6px;
      margin-top: 0px;
      display: inline-block;

      &--today {
        color: #000;
        border: solid 1px #4db5f0;
      }

      &--selected {
        background-color: #4db5f0;
      }

      &--DoW {
        margin-bottom: 4px;
      }

      &:not(.calender__gridCell--DoW):not(.calender__gridCell--emply) {
        :hover {
          background-color: #e8e8e8;
          border-radius: 50%;
        }
      }
    }
  }

}

.calender::before {
  background-color: inherit;

  filter: blur(6px);
}
