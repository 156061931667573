.MoneyInput {
  display: inline-block;
  width: 100%;


  input {
    font-family: 'Lato', sans-serif;
    font-size: 1.25rem;
    height: 35px;
  }
}
