/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.DateRangeField {
  margin: auto 8px; }
  .DateRangeField > div {
    display: inline-block; }
  .DateRangeField__datepicker {
    width: 105px;
    height: 40px;
    border: 0;
    border-bottom: 1px solid #A2A2A2;
    outline: 0;
    letter-spacing: 0.04em;
    padding-bottom: 0;
    white-space: nowrap; }
    .DateRangeField__datepicker--not-selected {
      color: #A2A2A2; }
  .DateRangeField__arrow {
    width: 24px;
    height: 40px;
    object-fit: contain;
    border: none;
    border-bottom: 1px solid #A2A2A2;
    vertical-align: top; }
    .DateRangeField__arrow svg {
      margin-top: 8px; }
