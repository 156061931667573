.loginView {
  background-color: #262D34;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .loginForm {
    margin: calc(50vh - 300px) auto;
    padding-top: 60px;
    width: 420px;
    height: 610px;
    border-radius: 2px;

    background-color: #ffffff;
    box-shadow: 0 1px 6px 0 rgba(14, 20, 26, 0.2);

    &__inner {
      width: 300px;
      margin: auto;

      &__welcomeText {
        height: 24px;
        font-family: Lato sans-serif;
        font-size: 20px;
        white-space: nowrap;
        color: #0e141a;
        text-align: center;
      }

      &__instruction {
        height: 17px;
        font-family: Lato sans-serif;
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
        color: #0e141a;
      }

      &__inputArea {
        width: auto;
        height: 122px;
        margin-top: 40px;
        position: relative;
        .textInput {
          margin: 0 0 22px 0;
          width: 300px;
        }
      }

      &__Error {
        width: 300px;
        height: 80px;
        font-family: NotoSansCJKjp sans-serif;
      }

      button {
        width: 300px;
        height: 36px;
        margin: 20px 0;
        object-fit: contain;
        font-size: 14px;
      }

      &__tryButton:disabled {
        border: solid 1px #e8e8e8;
        color: #e8e8e8;
      }

      &__forgotText {
        height: 14px;
        font-size: 14px;
        text-align: center;
        color: #4db5f0;
      }
    }
  }
}
