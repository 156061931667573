/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.MonthlyView {
  position: relative; }
  .MonthlyView--1 {
    width: calc(240px + 119px * 1); }
  .MonthlyView--2 {
    width: calc(240px + 119px * 2); }
  .MonthlyView--3 {
    width: calc(240px + 119px * 3); }
  .MonthlyView--4 {
    width: calc(240px + 119px * 4); }
  .MonthlyView--5 {
    width: calc(240px + 119px * 5); }
  .MonthlyView--6 {
    width: calc(240px + 119px * 6); }
  .MonthlyView--7 {
    width: calc(240px + 119px * 7); }
  .MonthlyView--8 {
    width: calc(240px + 119px * 8); }
  .MonthlyView--9 {
    width: calc(240px + 119px * 9); }
  .MonthlyView--10 {
    width: calc(240px + 119px * 10); }
  .MonthlyView--11 {
    width: calc(240px + 119px * 11); }
  .MonthlyView--12 {
    width: calc(240px + 119px * 12); }
  .MonthlyView--13 {
    width: calc(240px + 119px * 13); }
  .MonthlyView--14 {
    width: calc(240px + 119px * 14); }
  .MonthlyView--15 {
    width: calc(240px + 119px * 15); }
  .MonthlyView--16 {
    width: calc(240px + 119px * 16); }
  .MonthlyView--17 {
    width: calc(240px + 119px * 17); }
  .MonthlyView--18 {
    width: calc(240px + 119px * 18); }
  .MonthlyView--19 {
    width: calc(240px + 119px * 19); }
  .MonthlyView--20 {
    width: calc(240px + 119px * 20); }
  .MonthlyView--21 {
    width: calc(240px + 119px * 21); }
  .MonthlyView--22 {
    width: calc(240px + 119px * 22); }
  .MonthlyView--23 {
    width: calc(240px + 119px * 23); }
  .MonthlyView--24 {
    width: calc(240px + 119px * 24); }
  .MonthlyView--25 {
    width: calc(240px + 119px * 25); }
  .MonthlyView--26 {
    width: calc(240px + 119px * 26); }
  .MonthlyView--27 {
    width: calc(240px + 119px * 27); }
  .MonthlyView--28 {
    width: calc(240px + 119px * 28); }
  .MonthlyView--29 {
    width: calc(240px + 119px * 29); }
  .MonthlyView--30 {
    width: calc(240px + 119px * 30); }
  .MonthlyView--31 {
    width: calc(240px + 119px * 31); }
  .MonthlyView__Row {
    display: grid; }
    .MonthlyView__Row__Lines--1 {
      grid-template-rows: calc(60px * 1); }
    .MonthlyView__Row__Lines--2 {
      grid-template-rows: calc(60px * 2); }
    .MonthlyView__Row__Lines--3 {
      grid-template-rows: calc(60px * 3); }
    .MonthlyView__Row__Lines--4 {
      grid-template-rows: calc(60px * 4); }
    .MonthlyView__Row__Lines--5 {
      grid-template-rows: calc(60px * 5); }
    .MonthlyView__Row__Lines--6 {
      grid-template-rows: calc(60px * 6); }
    .MonthlyView__Row__Lines--7 {
      grid-template-rows: calc(60px * 7); }
    .MonthlyView__Row__Lines--8 {
      grid-template-rows: calc(60px * 8); }
    .MonthlyView__Row__Lines--9 {
      grid-template-rows: calc(60px * 9); }
    .MonthlyView__Row__Dates {
      top: 56px;
      position: -webkit-sticky;
      position: sticky;
      z-index: 3;
      background-color: #fff; }
    .MonthlyView__Row__Header {
      grid-row: 1;
      grid-gap: 0 0;
      width: 220px;
      border: solid 1px #ddd;
      font-size: 1.2rem;
      display: table;
      height: 0;
      background-color: #fff;
      left: 0;
      position: -webkit-sticky;
      position: sticky;
      z-index: 2; }
      .MonthlyView__Row__Header__Lines--1 {
        height: calc(60px * 1); }
      .MonthlyView__Row__Header__Lines--2 {
        height: calc(60px * 2); }
      .MonthlyView__Row__Header__Lines--3 {
        height: calc(60px * 3); }
      .MonthlyView__Row__Header__Lines--4 {
        height: calc(60px * 4); }
      .MonthlyView__Row__Header__Lines--5 {
        height: calc(60px * 5); }
      .MonthlyView__Row__Header__Lines--6 {
        height: calc(60px * 6); }
      .MonthlyView__Row__Header__Lines--7 {
        height: calc(60px * 7); }
      .MonthlyView__Row__Header__Lines--8 {
        height: calc(60px * 8); }
      .MonthlyView__Row__Header__Lines--9 {
        height: calc(60px * 9); }
      .MonthlyView__Row__Header__Name {
        display: table-cell;
        vertical-align: middle;
        padding: 2px; }
    .MonthlyView__Row__Cell {
      grid-row: 1;
      width: 120px;
      border: solid 1px #ddd;
      position: relative;
      display: table;
      height: 100%; }
      .MonthlyView__Row__Cell__Date {
        text-align: center;
        font-size: 1.5rem;
        display: table-cell;
        vertical-align: middle;
        background-color: #fff;
        padding: 1px; }
        .MonthlyView__Row__Cell__Date .today {
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: #4db5f0;
          border-radius: 5px;
          margin-right: 2.5px;
          margin-bottom: 3px; }
      .MonthlyView__Row__Cell__Day {
        font-size: 1.2rem;
        margin-left: 3px; }
    .MonthlyView__Row__Stay__Container {
      z-index: 1;
      position: absolute; }
    .MonthlyView__Row__Stay {
      display: block;
      height: 50px;
      line-height: 50px;
      margin-top: 4px;
      margin-right: 4px;
      margin-left: calc(120px / 2);
      vertical-align: middle;
      width: 0;
      cursor: pointer;
      overflow: hidden; }
      .MonthlyView__Row__Stay--0 {
        width: 118px;
        border-right: transparent; }
      .MonthlyView__Row__Stay--1 {
        width: calc(118px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--2 {
        width: calc(238px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--3 {
        width: calc(358px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--4 {
        width: calc(478px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--5 {
        width: calc(598px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--6 {
        width: calc(718px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--7 {
        width: calc(838px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--8 {
        width: calc(958px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--9 {
        width: calc(1078px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--10 {
        width: calc(1198px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--11 {
        width: calc(1318px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--12 {
        width: calc(1438px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--13 {
        width: calc(1558px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--14 {
        width: calc(1678px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--15 {
        width: calc(1798px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--16 {
        width: calc(1918px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--17 {
        width: calc(2038px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--18 {
        width: calc(2158px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--19 {
        width: calc(2278px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--20 {
        width: calc(2398px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--21 {
        width: calc(2518px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--22 {
        width: calc(2638px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--23 {
        width: calc(2758px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--24 {
        width: calc(2878px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--25 {
        width: calc(2998px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--26 {
        width: calc(3118px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--27 {
        width: calc(3238px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--28 {
        width: calc(3358px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--29 {
        width: calc(3478px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--30 {
        width: calc(3598px);
        border-right: transparent; }
      .MonthlyView__Row__Stay--31 {
        width: calc(3718px);
        border-right: transparent; }
      .MonthlyView__Row__Stay__SiteReferer {
        width: 14px;
        height: 14px;
        display: inline-block;
        object-fit: contain;
        background-size: contain;
        border: none;
        margin-left: 10px;
        vertical-align: middle; }
      .MonthlyView__Row__Stay__Name {
        padding-left: 3px; }
      .MonthlyView__Row__Stay__Status--check_in {
        background-color: #fdf3ea;
        color: #F67806; }
      .MonthlyView__Row__Stay__Status--staying {
        background-color: #e5f8f3;
        color: #00BA8A; }
      .MonthlyView__Row__Stay__Status--check_out {
        background-color: #e6eaf1;
        color: #0A2D73; }
      .MonthlyView__Row__Stay__Status--cancel {
        background-color: #e8e8e8;
        color: #818181; }
      .MonthlyView__Row__Stay__Status--unknown {
        background-color: #e8e8e8;
        color: #818181; }
      .MonthlyView__Row__Stay__Status--cleaning {
        background-color: #e8e8e8;
        color: #818181;
        margin-left: 1px; }
        .MonthlyView__Row__Stay__Status--cleaning a {
          color: #818181;
          text-decoration: none; }
      .MonthlyView__Row__Stay__FromLastMonth {
        margin-left: calc(120px / 2 * -1);
        text-align: center;
        position: absolute; }
        .MonthlyView__Row__Stay__FromLastMonth--1 {
          top: calc(60px * 1); }
        .MonthlyView__Row__Stay__FromLastMonth--2 {
          top: calc(60px * 2); }
        .MonthlyView__Row__Stay__FromLastMonth--3 {
          top: calc(60px * 3); }
        .MonthlyView__Row__Stay__FromLastMonth--4 {
          top: calc(60px * 4); }
        .MonthlyView__Row__Stay__FromLastMonth--5 {
          top: calc(60px * 5); }
        .MonthlyView__Row__Stay__FromLastMonth--6 {
          top: calc(60px * 6); }
        .MonthlyView__Row__Stay__FromLastMonth--7 {
          top: calc(60px * 7); }
        .MonthlyView__Row__Stay__FromLastMonth--8 {
          top: calc(60px * 8); }
        .MonthlyView__Row__Stay__FromLastMonth--9 {
          top: calc(60px * 9); }
      .MonthlyView__Row__Stay__ToNextMonth--1 {
        width: calc(120px * 1 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--2 {
        width: calc(120px * 2 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--3 {
        width: calc(120px * 3 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--4 {
        width: calc(120px * 4 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--5 {
        width: calc(120px * 5 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--6 {
        width: calc(120px * 6 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--7 {
        width: calc(120px * 7 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--8 {
        width: calc(120px * 8 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--9 {
        width: calc(120px * 9 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--10 {
        width: calc(120px * 10 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--11 {
        width: calc(120px * 11 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--12 {
        width: calc(120px * 12 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--13 {
        width: calc(120px * 13 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--14 {
        width: calc(120px * 14 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--15 {
        width: calc(120px * 15 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--16 {
        width: calc(120px * 16 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--17 {
        width: calc(120px * 17 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--18 {
        width: calc(120px * 18 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--19 {
        width: calc(120px * 19 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--20 {
        width: calc(120px * 20 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--21 {
        width: calc(120px * 21 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--22 {
        width: calc(120px * 22 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--23 {
        width: calc(120px * 23 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--24 {
        width: calc(120px * 24 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--25 {
        width: calc(120px * 25 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--26 {
        width: calc(120px * 26 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--27 {
        width: calc(120px * 27 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--28 {
        width: calc(120px * 28 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--29 {
        width: calc(120px * 29 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--30 {
        width: calc(120px * 30 - 120px / 2);
        border-right: none; }
      .MonthlyView__Row__Stay__ToNextMonth--31 {
        width: calc(120px * 31 - 120px / 2);
        border-right: none; }
