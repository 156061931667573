.NavigationItem {
  height: 40px;
  padding: 0;
  margin: 0;
  opacity: 0.5;

  &:hover {
    background-color: #1a1c1f;
    opacity: 1.0;
  }
  a {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 0 16px;
    text-decoration: none;
  }
  &__Row {
    width: 100%;
    height: 40px;
  }
  &__Icon {
    float: left;
    vertical-align:middle;
    height: 40px;
    line-height: 40px;
    padding: 5px 0;
  }
  &__Label {
    vertical-align:middle;
    height: 40px;
    line-height: 40px;
    font-weight: 100;
    color: #fff;
    padding: 0 10px;
  }
}

@media (max-width: 768x)  {
  .NavigationItem {
    &__Label {
      display: none;
  }
}
}
