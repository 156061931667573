.OtaLogo {
  display: inline-block;
  width: 26px;
  height: 26px;
  object-fit: contain;
  background-size: contain;
  border: none;

  &-airbnb {
    background: url('/client_assets/icon/ota/icon-airbnb.png') no-repeat;
  }

  &-booking {
    background: url('/client_assets/icon/ota/icon-booking.png') no-repeat;
  }

  &-expedia {
    background: url('/client_assets/icon/ota/icon-expedia.png') no-repeat;
  }

  &-hotels {
    background: url('/client_assets/icon/ota/icon-hotels.png') no-repeat;
  }

  &-ctrip {
    background: url('/client_assets/icon/ota/icon-ctrip.svg') no-repeat;
  }

  &-rakuten {
    background: url('/client_assets/icon/ota/icon-rakuten.png') no-repeat;
  }

  &-ikyu {
    background: url('/client_assets/icon/ota/icon-ikyu.png') no-repeat;
  }

  &-jalan {
    background: url('/client_assets/icon/ota/icon-jalan.png') no-repeat;
  }

  &-hafh {
    background: url('/client_assets/icon/ota/icon-hafh.png') no-repeat;
  }

  &-ota {
    background: url('/client_assets/icon/ota/icon-ota.svg') no-repeat;
  }
}
