/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

Button {
  font-weight: normal;
  text-align: center;
  border-radius: 2px;
  outline: 0; }
  Button:focus {
    outline: 0; }

Button,
Button::before,
Button::after {
  box-sizing: border-box;
  transition: all .3s; }

.btn-primary {
  color: #4DB5F0;
  border: solid 1px #4DB5F0; }
  .btn-primary:hover {
    color: #F9FAFC;
    background-color: #4DB5F0; }
  .btn-primary:disabled {
    opacity: 0.2;
    color: #4DB5F0;
    background-color: #F9FAFC; }

.btn-secondary {
  color: #F9FAFC;
  background-color: #4DB5F0;
  border: solid 1px #4DB5F0; }
  .btn-secondary:hover {
    color: #c6d0e3;
    background-color: #1394de;
    border: solid 1px #1394de; }
  .btn-secondary:disabled {
    opacity: 0.2;
    color: #F9FAFC;
    background-color: #4DB5F0; }

.btn-dark {
  color: #0E141A;
  border: solid 1px #A2A2A2; }
  .btn-dark:hover {
    opacity: 0.6; }
  .btn-dark:disabled {
    opacity: 0.2; }

.btn-light {
  color: #0E141A;
  border: none; }
  .btn-light:hover {
    color: #0E141A;
    background-color: #E8E8E8; }
  .btn-light:disabled {
    opacity: 0.2;
    background-color: #F9FAFC; }

.btn-blue {
  color: #4DB5F0;
  border: none; }
  .btn-blue:hover {
    color: #4DB5F0;
    background-color: rgba(77, 181, 240, 0.1); }
  .btn-blue:disabled {
    opacity: 0.2;
    background-color: #F9FAFC; }

.btn-danger {
  color: #DB2D26;
  border: none; }
  .btn-danger:hover {
    color: #F9FAFC;
    background-color: #DB2D26; }
  .btn-danger:disabled {
    opacity: 0.2;
    color: #DB2D26;
    background-color: #F9FAFC; }

.btn-lg {
  height: 34px;
  line-height: 14px;
  padding: 8px 16px 9px 16px; }

.btn-md {
  font-size: 0.85rem;
  height: 26px;
  line-height: 14px; }
