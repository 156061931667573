/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.tag {
  display: inline;
  min-width: 37px;
  height: 19px;
  border-radius: 15px;
  padding: 2px 6px;
  font-family: NotoSansCJKjp, Lato, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-size: 12px;
  text-align: left; }
  .tag span {
    white-space: nowrap; }

.tag-check_in {
  background-color: #fdf3ea;
  color: #F67806; }

.tag-staying {
  background-color: #e5f8f3;
  color: #00BA8A; }

.tag-check_out {
  background-color: #e6eaf1;
  color: #0A2D73; }

.tag-cancelled {
  background-color: #A2A2A2;
  color: #0A2D73; }
