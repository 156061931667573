.segment input{
  display: none;
}
.segment label{
  display: block;
  float: left;
  cursor: pointer;
  width: 80px;
  margin: 0;
  padding: 12px 5px;
  border-right: 1px solid #abb2b7;
  background: #bdc3c7;
  color: #555e64;
  font-size: 14px;
  text-align: center;
  line-height: 1;
  transition: .2s;
}
.segment label:first-of-type{
  border-radius: 3px 0 0 3px;
}
.segment label:last-of-type{
  border-right: 0px;
  border-radius: 0 3px 3px 0;
}
.segment input[type="radio"]:checked + label {
  background-color: #00BA8A;
  color: #fff;
}
