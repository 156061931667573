.SensorValue {

  .labels {
    position: relative;
    font-family: Lato, sans-serif;

    .value-label {
      font-size: 1.43rem;
    }

    .unit-label {
      position: absolute;
      top: 2px;
    }
  }
}

