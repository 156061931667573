/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

.Edit {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10001;
  overflow: scroll; }
  .Edit .Toolbar {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .Edit .Toolbar--left {
      width: auto;
      margin-left: 36px; }
      .Edit .Toolbar--left button {
        border: none;
        background-color: transparent;
        cursor: pointer; }
        .Edit .Toolbar--left button:hover {
          opacity: 0.6; }
    .Edit .Toolbar--center {
      width: 33%;
      text-align: center; }
    .Edit .Toolbar--right {
      width: auto; }
      .Edit .Toolbar--right button {
        line-height: 32px; }
  .Edit .EditArea {
    width: 100vw;
    max-width: 640px;
    height: 100vh;
    background-color: #fff;
    margin: 60px auto;
    padding: 30px;
    z-index: 10000; }
    .Edit .EditArea__row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 20px; }
      .Edit .EditArea__row p {
        margin: 0 0 3px 0;
        padding: 0; }
      .Edit .EditArea__row--full {
        width: 90%; }
      .Edit .EditArea__row--half {
        width: 40%; }
        .Edit .EditArea__row--half:nth-child(odd) {
          margin-right: 15px; }
        .Edit .EditArea__row--half:nth-child(even) {
          margin-left: 15px; }
        .Edit .EditArea__row--half--quater {
          display: inline-block;
          width: 45%; }
          .Edit .EditArea__row--half--quater:nth-child(odd) {
            margin-right: 5px; }
          .Edit .EditArea__row--half--quater:nth-child(even) {
            margin-left: 5px; }
      .Edit .EditArea__row--one-third {
        width: 30%; }
    .Edit .EditArea__column {
      display: flex;
      flex-direction: column;
      width: 100%; }
    .Edit .EditArea__Deletion {
      padding-top: 20px;
      padding-right: 8px;
      float: right; }
