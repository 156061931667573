/* Color palette */
/* Component styles */
/* Text styles */
title {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.43rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e141a; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

.input-required:after {
  content: "*";
  color: #db2d26;
  padding: 3px;
  line-height: 15px; }

#SettingTablet .SettingTablet__Toolbar {
  height: 60px; }
  #SettingTablet .SettingTablet__Toolbar .back {
    position: absolute;
    line-height: 60px;
    vertical-align: middle; }
    #SettingTablet .SettingTablet__Toolbar .back a {
      color: #0e141a; }
  #SettingTablet .SettingTablet__Toolbar .title {
    margin: auto 0;
    text-align: center;
    vertical-align: middle;
    line-height: 60px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300; }

@media (max-width: 767.98px) {
  #SettingTablet .SettingTablet__Toolbar {
    height: 40px; }
    #SettingTablet .SettingTablet__Toolbar .back {
      position: absolute;
      float: left;
      line-height: 36px;
      margin-left: 30px; }
    #SettingTablet .SettingTablet__Toolbar .title {
      line-height: 40px;
      font-size: 1rem; } }

#SettingTablet .SettingTablet__NavigationTabs {
  padding: 20px 25px; }
  #SettingTablet .SettingTablet__NavigationTabs .title {
    margin-top: 30px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1.43rem;
    font-weight: 300;
    display: flex; }
  #SettingTablet .SettingTablet__NavigationTabs .previewButton {
    width: 24px;
    height: 24px;
    background: url("/client_assets/button/btn-hint.svg") no-repeat;
    margin-left: 8px;
    margin-top: 4px;
    cursor: pointer; }
  #SettingTablet .SettingTablet__NavigationTabs ul.NavigationLinks {
    list-style-type: none;
    padding: 0; }
    #SettingTablet .SettingTablet__NavigationTabs ul.NavigationLinks li {
      padding: 0;
      margin-bottom: 15px; }
    #SettingTablet .SettingTablet__NavigationTabs ul.NavigationLinks li > a {
      color: #4db5f0; }
  #SettingTablet .SettingTablet__NavigationTabs .BasicGroup {
    margin-bottom: 40px; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .basicForm .icon {
      vertical-align: middle; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .basicLabel {
      margin-bottom: 8px; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .basicLabel2 {
      vertical-align: middle;
      line-height: 60px; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .basicNote {
      color: #818181;
      margin-bottom: 8px; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .Cell__FileUpload .ImgIcon {
      vertical-align: middle;
      margin: 0 5px; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .Cell__FileUpload .FileUpload {
      display: inline-block;
      vertical-align: middle;
      height: 34px; }
      #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .Cell__FileUpload .FileUpload .fileInput__label {
        display: none; }
      #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .Cell__FileUpload .FileUpload .fileInput__message {
        display: inline; }
    #SettingTablet .SettingTablet__NavigationTabs .BasicGroup .mapUrl {
      padding-right: 20px;
      width: 260px; }
  #SettingTablet .SettingTablet__NavigationTabs .ManualGroup {
    margin-bottom: 40px; }
    #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .ListItem {
      padding-right: 0; }
    #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .label {
      vertical-align: middle;
      line-height: 40px; }
      #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .label .icon {
        margin: auto 10px;
        vertical-align: middle; }
    #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .more {
      float: right;
      margin: 13px 10px; }
      #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .more .editButton {
        display: inline-block;
        padding: 0 10px; }
      #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .more .deleteButton {
        display: inline-block;
        padding: 0 3px; }
    #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .sort {
      float: right;
      margin: 13px 10px; }
      #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .sort button {
        cursor: move;
        border: none;
        background: none; }
    #SettingTablet .SettingTablet__NavigationTabs .ManualGroup .addManual {
      margin: 5px 0; }
  #SettingTablet .SettingTablet__NavigationTabs .TerminalGroup {
    margin-bottom: 40px; }
    #SettingTablet .SettingTablet__NavigationTabs .TerminalGroup .logoutCell {
      float: right;
      display: none; }
      #SettingTablet .SettingTablet__NavigationTabs .TerminalGroup .logoutCell .logoutTerminal {
        margin-right: 0; }

#SettingTablet .ListItem:hover .logoutCell {
  display: block; }

#SettingTablet .TabletPreviewDialog {
  width: 726px;
  height: 516px;
  background-color: #fff;
  margin: 10vh auto 0 auto;
  z-index: 100001; }
  #SettingTablet .TabletPreviewDialog__image {
    display: flex;
    width: 100%;
    height: auto;
    padding-top: 96px; }
    #SettingTablet .TabletPreviewDialog__imageTop {
      width: 210px;
      height: 280px;
      background: url("/client_assets/tablet/top.jpg") no-repeat;
      background-size: contain;
      margin-left: 24px; }
    #SettingTablet .TabletPreviewDialog__imageSelectLang {
      width: 210px;
      height: 280px;
      background: url("/client_assets/tablet/selectLang.jpg") no-repeat;
      background-size: contain;
      margin-left: 24px; }
    #SettingTablet .TabletPreviewDialog__imageMenu {
      width: 210px;
      height: 280px;
      background: url("/client_assets/tablet/menu.jpg") no-repeat;
      background-size: contain;
      margin-left: 24px; }
  #SettingTablet .TabletPreviewDialog__comments--top {
    font-size: 14px;
    font-family: 'Noto Sans Japanese', sans-serif;
    position: absolute;
    margin-top: -44px;
    margin-left: 87px; }
  #SettingTablet .TabletPreviewDialog__comments--topLogo {
    font-size: 14px;
    font-family: 'Noto Sans Japanese', sans-serif;
    position: absolute;
    margin-top: 298px;
    margin-left: 87px; }
  #SettingTablet .TabletPreviewDialog__comments--selectLangLogo {
    font-size: 14px;
    font-family: 'Noto Sans Japanese', sans-serif;
    position: absolute;
    margin-top: -44px;
    margin-left: 307px; }
  #SettingTablet .TabletPreviewDialog__comments--menu {
    font-size: 14px;
    font-family: 'Noto Sans Japanese', sans-serif;
    position: absolute;
    margin-top: 298px;
    margin-left: 534px; }
  #SettingTablet .TabletPreviewDialog button {
    width: 88px;
    height: 34px;
    margin-right: 24px;
    margin-top: 86px;
    float: right; }

#SettingTablet .ManualDialog {
  width: 450px;
  background-color: #fff;
  margin: 10vh auto 0 auto;
  z-index: 100001;
  padding: 22px;
  font-size: 0.9rem; }
  #SettingTablet .ManualDialog .title {
    margin-top: 0; }
  #SettingTablet .ManualDialog .required {
    color: #ff0000; }
  #SettingTablet .ManualDialog__pdf {
    margin-bottom: 24px; }
  #SettingTablet .ManualDialog__title {
    margin-bottom: 24px; }
    #SettingTablet .ManualDialog__title p {
      margin-bottom: 5px; }
    #SettingTablet .ManualDialog__title .LabeledInputField input[type=text] {
      width: 110px;
      margin: auto 0 auto 0; }
  #SettingTablet .ManualDialog__keywords {
    margin-bottom: 24px; }
    #SettingTablet .ManualDialog__keywords p {
      margin-bottom: 5px; }
    #SettingTablet .ManualDialog__keywords .help {
      border-radius: 2px;
      background-color: #e6eaf1;
      font-size: 0.9rem;
      padding: 4px;
      margin-left: 10px; }
    #SettingTablet .ManualDialog__keywords .LabeledInputField input[type=text] {
      width: 320px;
      margin: auto 0 auto 0; }
  #SettingTablet .ManualDialog .LabeledInputField {
    border-bottom: solid 1px #a2a2a2;
    margin: 10px auto 10px auto;
    padding-bottom: 8px; }
    #SettingTablet .ManualDialog .LabeledInputField span {
      background-color: #e8e8e8;
      padding: 2px 4px 2px 4px;
      border-radius: 2px;
      font-size: 0.9rem; }
    #SettingTablet .ManualDialog .LabeledInputField input[type=text] {
      border: none;
      margin-left: 5px; }
      #SettingTablet .ManualDialog .LabeledInputField input[type=text]:focus {
        outline: 0; }
  #SettingTablet .ManualDialog__action {
    width: 100%;
    height: 30px; }
    #SettingTablet .ManualDialog__action .buttons {
      float: right; }
      #SettingTablet .ManualDialog__action .buttons button {
        margin: 0 6px; }
