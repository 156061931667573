
.Snackbar {
  position: fixed;
  z-index: 100001;
  top: 5vh;
  left: 25vw;
  width: 50vw;

  margin: 0 auto;
  border-radius: 2px;
  background-color: rgba(38, 45, 52, 0.9);
  vertical-align: middle;
  padding: 10px;

  &__pict {
    width: 20px;
    height: 20px;
    margin: 10px;
    display: inline-block;
    float: left;
  }

  &__pict--success {
    background: url('/client_assets/icon/icon-correct.svg') no-repeat;
    background-size: contain;
  }

  // &__pict--error {
  //   background: url('/client_assets/icon/icon-wrong.svg') no-repeat;
  //   background-size: contain;
  // }

  &__message {
    color: #fff;
    line-height: 40px;
  }

  &__close {
    cursor: pointer;
    display: inline-block;
    float: right;
    margin-top: 8px;
    margin-left: 5px;
  }
}

